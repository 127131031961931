


import {ShopStatus,ShopButtonStatus} from "components/fragments/ShopStatus";
import React, { useState, useEffect,useRef } from "react";
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import { Link, useParams, NavLink } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Icon } from '@iconify/react';

import ipiechart from "assets/svg/PieChart.svg";
import icard from "assets/svg/card.svg";
import ihistory from "assets/svg/History.svg";
import ishop2 from "assets/svg/Shop2.svg";
import itag from "assets/svg/tag.svg";
import iusercircle from "assets/svg/UserCircle.svg";
import iuserid from "assets/svg/UserId.svg";
import Avatar from '@mui/material/Avatar';
import { Formik } from 'formik';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';

import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import {prefix,date,months,years,gender,nationality,tourist,touristinitial} from "mocks/tourist";

import { ProvinceList } from 'components/provinces/province'

export function TouristInformation () { 


    let { id } = useParams();
    console.log(id)

    

    const inputFileRef = useRef(null);
    const [image, setImage] = useState(null);
    

    const [formdata, setFormdata] = useState(touristinitial)

    const schema = yup.object().shape({
        prefix: yup.string().required(),
        name: yup.string().required(),
        surname: yup.string().required(),

        date: yup.string().required(),
        month: yup.string().required(),
        year: yup.string().required(),
        gender: yup.string().required(),
        phoneNumber: yup.string().required(),
        status: yup.number().required(),
        
    });

    


    useEffect(() => {

        if(id){
            setFormdata(tourist)
        }
        
       

    }, []);


    
    const countryList = [
        {
          value: 'ประเทศไทย',
          label: 'ประเทศไทย',
        }
    ];
    

    return (
        <>
            
            <div className="bg-white  rounded-[10px] drop-shadow py-3 mb-4 p-[25px]">
                <div className="text-[#31A7DE] font-bold text-lg mb-5">ข้อมูลส่วนตัว</div>
                <div className="text-[#4C4E64]/[0.68]  text-base mb-5">ภาพโปรไฟล์</div>

                

                <Formik
                    
                    initialValues={formdata}
                    validationSchema={schema}
                    enableReinitialize
                    onSubmit={async (values) => {

                        alert('save')
                        
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        submitForm
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="flex items-center mb-9">
                                <Avatar
                                    alt=""
                                    src={image ?  image : values.image}
                                    sx={{ width: 101, height: 101 }}
                                />
                                <div className="ml-6">
                                    <Button 
                                        variant="contained" 
                                        className="w-[140px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[38px] mr-3"
                                        onClick={() => inputFileRef.current.click()}
                                    >
                                        อัปโหลดรูปภาพ
                                    </Button>
                                    <Button 
                                        variant="outlined" 
                                        className="w-[62px] border-[#FF4D49]/[0.5] text-[#FF4D49] rounded-[8px] text-sm mr-3  h-[38px]"
                                        onClick={() => {
                                            inputFileRef.current.value = ""
                                            setImage(null)
                                        }}
                                    >
                                        ลบ
                                    </Button>
                                    

                                    <div className="text-[#4C4E64]/[0.68]  text-xs mt-5">อัพโหลดไฟล์ JPG, GIF or PNG. ขนาดไม่เกิน 800K</div>
                                    <input
                                        ref={inputFileRef}
                                        accept="image/*"
                                        hidden
                                        id="avatar-image-upload"
                                        type="file"
                                        onChange={(event) => {

                                            const newImage = event.target?.files?.[0];
                                            if (newImage) { 
                                                setImage(URL.createObjectURL(newImage));
                                                setFieldValue('image',newImage)
                                            }

                                        }}
                                        
                                    />
                                </div>
                            </div>

                            <div className="grid grid-cols-12 gap-7 mb-6">
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField 
                                        disabled
                                        label="รหัสนักท่องเที่ยว" 
                                        value={values.touristCode}
                                        name='touristCode'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!errors.touristCode}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    />
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField 
                                        
                                        select
                                        label="คำนำหน้า"
                                        name='prefix'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.prefix}
                                    
                                        error={!!errors.prefix}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    >
                                        {prefix.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField
                                        
                                        label="ชื่อ"
                                        name='name'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                        error={!!errors.name}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    />
                                    
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField
                                        
                                        label="นามสกุล"
                                        name='surname'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.surname}
                                        error={!!errors.surname}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    />
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField 
                                        
                                        select
                                        label="วันที่"
                                        name='date'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.date}
                                    
                                        error={!!errors.date}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    >
                                        {date.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField 
                                        
                                        select
                                        label="เดือนเกิด"
                                        name='month'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.month}
                                    
                                        error={!!errors.month}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    >
                                        {months.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField 
                                        
                                        select
                                        label="ปีเกิด"
                                        name='year'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.year}
                                    
                                        error={!!errors.year}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    >
                                        {years().map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField 
                                        
                                        select
                                        label="เพศ"
                                        name='gender'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.gender}
                                    
                                        error={!!errors.gender}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    >
                                        {gender.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField 
                                        
                                        select
                                        label="สัญชาติ"
                                        name='nationality'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    
                                        value={values.nationality}
                                        error={!!errors.nationality}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    >
                                        {nationality.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField 
                                        
                                        select
                                        label="จังหวัด"
                                        name='province'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.province}
                                    
                                        error={!!errors.province}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    >
                                        {ProvinceList.map((option) => (
                                            <MenuItem key={option.label} value={option.label}>
                                            {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField 
                                        
                                        select
                                        label="ประเทศ"
                                        name='country'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.country}
                                    
                                        error={!!errors.country}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    >
                                        {countryList.map((option) => (
                                            <MenuItem key={option.label} value={option.value}>
                                            {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField
                                        
                                        label="เบอร์โทร"
                                        name='phoneNumber'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phoneNumber}
                                        error={!!errors.phoneNumber}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    />
                                </div>
                            </div>

                            

                            <div className="mb-6">
                                <FormControl>
                                    <div>สถานะ</div> 
                                    <RadioGroup
                                        row
                                        aria-labelledby=""
                                        name="status"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={ values.status}
                        
                                    >
                                        <FormControlLabel value="1" control={<Radio />} label="เปิดใช้งาน" />
                                        <FormControlLabel value="0" control={<Radio />} label="ปิดใช้งาน" />
                                    </RadioGroup>
                                </FormControl>
                            </div>

                            <div className="flex justify-end mb-7">
                                    <Button 
                                        variant="outlined" 
                                        className="w-[111px] border-[#6D788D]/[0.5] text-[#6D788D] rounded-[8px] text-sm mr-3  h-[44px]"
                                        href="/manage/shop/list"
                                    >
                                        ยกเลิก
                                    </Button>
                                    <Button 
                                        variant="contained" 
                                        className="w-[111px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[44px] mr-3"
                                        type="submit"
                                    >
                                        บันทึก
                                    </Button>
                                    
                            </div>
                        </form>
                    )}
                </Formik>
               
            </div>
        </>
    )
}


