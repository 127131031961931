
import DownloadExcel from "components/utils/DownloadExcel";
import {ShopStatus,ShopButtonStatus} from "components/fragments/ShopStatus";
import ic_edit from "assets/svg/ic_edit.svg";
import IconButton from '@mui/material/IconButton';

import React, { useState, useEffect,useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import { Link, useParams, NavLink } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import Chip from '@mui/material/Chip';
import ic_trash from "assets/icon/solar_trash-bin-trash-outline.svg";
import { Password } from "@mui/icons-material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import Avatar from '@mui/material/Avatar';
import { Formik } from 'formik';
import * as yup from 'yup';

import {prefix,position,permissions} from "mocks/emp";
import TextField from '@mui/material/TextField';

import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import {ModalSuccess} from "components/layouts/ModalSuccess";
import {ModalDeleteSuccess} from "components/layouts/ModalDeleteSuccess";

export function EmpShop () {


    const data = [
        { 
            id: 1, 
            prefix:"นาย",
            name: 'สถาปนิค', 
            surname:"บัวพิทักษ์",
            image: '/images/tourist/t1.png', 
            phoneNumber: '082 190 9009', 
            position:{
                id: 1,
                name: 'ผู้จัดการ',
            },
            permissions:{
                id: 1,
                name: 'แอดมิน',
            },
            password:"0661541519",
            status:1
        },
    ];


    const [emp, setEmp] = useState([]);

    useEffect(() => {
        setEmp(data)

    }, []);


    const [filterText, setFilterText] = useState("");

    let filteredItems = emp.filter((item) => {
        const nameMatches =
          item.name &&
          item.name.toLowerCase().includes(filterText.toLowerCase());
        return nameMatches;
    });

    const columns = [
        {
            field: 'password',
            headerName: 'รหัสเข้าใช้งาน',
            headerClassName:'bg-[#F5F5F7]',
            width: 150,
            editable: false,
    
            renderCell: ({ row }) => (
                <div>{row.password}</div>
            ),
        },
        {
            field: 'name',
            headerName: 'ชื่อ - นามสกุล',
            headerClassName:'bg-[#F5F5F7]',
            flex:1,
            width: 150,
            editable: false,
            renderCell: ({ row }) => (
                <div className="flex items-center">
                    <img src={row.image} className="w-10 h-10 rounded-full" />
                    <div className="ml-3">{row.prefix} {row.name} {row.surname}</div>
                </div>
            ),
        },
        {
            field: 'position',
            headerName: 'ตำแหน่ง',
            headerClassName:'bg-[#F5F5F7]',
            width: 110,
            flex:1,
            editable: false,
            renderCell: ({ row }) => (
                <div className="flex items-center">
                    <div className="">{row.position.name}</div>
                </div>
            ),
        },
    
        {
            field: 'permissions',
            headerName: 'สิทธิ์การใช้งาน',
            headerClassName:'bg-[#F5F5F7]',
            sortable: false,
            width: 160,
            renderCell: ({ row }) => (
                <div className="flex items-center">
                    <div className="">{row.permissions.name}</div>
                </div>
            ),
        },
        {
            field: 'id2',
            headerName: 'สถานะ',
            headerClassName:'bg-[#F5F5F7]',
            sortable: false,
            width: 160,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <>
                {
                    row.status == 1 ? (
                        <Chip label={"เปิดใช้งาน"} className="w-[88px]" style={{
                            color:'rgba(114, 225, 40, 0.87)',
                            background:'#EEFBE5',
                        }} />
                    ) : (
                        <Chip label={"ปิดใช้งาน"} className="w-[88px]" style={{
                            color:'#FF0000',
                            background:'#ff00001f',
                        }} />
                    )
                }
                    
                </>
                
            ),
        },
        {
            field: 'id',
            headerName: 'จัดการ',
            headerClassName:'bg-[#F5F5F7]',
            sortable: false,
            width: 160,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <>
                    <IconButton  className="w-[30px] mr-3 h-[30px] p-1" style={{
                        background:'#E3E8F0',
                    }} 
                    onClick={() => {
                        setEmpEdit(row)
                        setModalOpen(true)
                    }}
                    
                    >
                        <img src={ic_edit} alt="" />
                    </IconButton>
                </>
                
            ),
        },
    ];
    


    const [modalOpen, setModalOpen] = useState(false)
    const [modalSuccessOpen, setModalSuccessOpen] = useState(false)
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false)

    const [empEdit, setEmpEdit] = useState({});

    const initialemp = {
        "image":"",
        "prefix":"",
        "name":"",
        "surname":"",
        "position":"",
        "permissions":"",
        "status":""
    }
    return (
        <>

            <div className="bg-white rounded-[15px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden pt-[15px]">
                <div className="px-[10px] py-[10px]">
                    <div className="text-[#4C4E64]/[0.87] text-xl  px-4 font-semibold mb-5">ข้อมูลพนักงาน</div>
                    <div className="d-flex align-items-center px-4 mb-3 flex-wrap">
                        <div className="mr-auto" >
                            <DownloadExcel data={filteredItems} filename="ผู้ใช้งานระบบ" />
                        </div>


                        <div className="me-3 ">
                            <input
                                type="text"
                                className="form-control form-st-4 h-[38px] w-[250px]"
                                placeholder="ค้นหา"
                                onKeyUp={(e) => setFilterText(e.target.value)}
                            />
                        </div>
                        <Button
 
                        className="btn px-4 h-[38px] text-white  bg-[#31A7DE]"
                        onClick={() => {
                            setEmpEdit(initialemp)
                            
                            setModalOpen(true)
           
                        }
                        }
                        >
                            เพิ่มพนักงาน
                        </Button>
                    </div>
                </div>
                <div>
                    <DataGrid
                        getRowHeight={() => 'auto'}
                        rows={filteredItems}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 5,
                            },
                        },
                        }}
                        pageSizeOptions={[10, 20, 30]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        slotProps={{
                            pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
                        }}
                        sx={{ 
                            borderRadius:0,
                            '.MuiDataGrid-columnHeaderDraggableContainer': { 
                                backgroundColor:'#F5F5F7'
                            },
                            '.MuiDataGrid-topContainer':{
                                fontSize:'16px'
                            },
                            '.MuiDataGrid-cell':{
                                display:'flex',
                                alignItems:'center',
                                paddingTop:'10px',
                                paddingBottom:'10px',
                                fontSize:'16px'
                            }
                        }}
                    />
                </div>



                <ModalCreate setOpen={setModalOpen} open={modalOpen} empEdit={empEdit} setSuccessOpen={setModalSuccessOpen} setDeleteOpen={setModalDeleteOpen}  />
                <ModalSuccess setOpen={setModalSuccessOpen} open={modalSuccessOpen}  />
                <ModalDeleteSuccess setOpen={setModalDeleteOpen} open={modalDeleteOpen}  />
            </div>
            
            
        </>
    );
}





const ModalCreate = ({setOpen,open,empEdit,setSuccessOpen,setDeleteOpen}) => {

    const handleClose = () => setOpen(false);


    const inputFileRef = useRef(null);
    const [image, setImage] = useState(null);

    const schema = yup.object().shape({
        prefix: yup.string().required(),
        name: yup.string().required(),
        surname: yup.string().required(),
        position: yup.string().required(),
        permissions: yup.string().required(),
        status: yup.number().required(),
        
    });

    const initialemp = {
        "image":"",
        "prefix":"",
        "name":"",
        "surname":"",
        "position":"",
        "permissions":"",
        "status":""
    }

    const [empForm, setEmpForm] = useState({});


    useEffect(() => {
        if(empEdit && empEdit.id){
            setEmpForm({
                "id":empEdit.id,
                "previewImage":empEdit.image,
                "prefix":empEdit.prefix,
                "name":empEdit.name,
                "surname":empEdit.surname,
                "position":empEdit.position.id,
                "permissions":empEdit.permissions.id,
                "status":empEdit.status
            })
        }else{
            setEmpForm(initialemp)
        }
        

    }, [empEdit]);


    return (
        <>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={styleModal} className="p-6 ">
                <div className="flex mb-3">
                    <div className="mr-auto text-xl text-[#4C4E64]/[0.87]">เพิ่มพนักงาน</div>
                    <IconButton onClick={() => {
                        setOpen(false)
                    }}>
                        <CloseSharpIcon className="text-[#4C4E64]/[0.54]" />
                    </IconButton>
                </div>
                <div>
                <Formik
                    
                    initialValues={empForm}
                    validationSchema={schema}
                    enableReinitialize
                    onSubmit={async (values) => {

               
                        setOpen(false)
                        setSuccessOpen(true)
                        
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        submitForm
                    }) => (
                        <form onSubmit={handleSubmit} className="px-2">
                            <div className="text-[#4C4E64]/[0.64] mb-2">ภาพโพรไฟล์ผู้ติดต่อ</div>
                            <div className="flex items-center mb-9">
                                <Avatar
                                    alt=""
                                    src={values.previewImage}
                                    sx={{ width: 101, height: 101 }}
                                />
                                <div className="ml-6">
                                    <Button 
                                        variant="contained" 
                                        className="w-[140px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[38px] mr-3"
                                        onClick={() => inputFileRef.current.click()}
                                    >
                                        อัปโหลดรูปภาพ
                                    </Button>
                                    <Button 
                                        variant="outlined" 
                                        className="w-[62px] border-[#FF4D49]/[0.5] text-[#FF4D49] rounded-[8px] text-sm mr-3  h-[38px]"
                                        onClick={() => {
                                            inputFileRef.current.value = ""
                                            setImage(null)
                                        }}
                                    >
                                        ลบ
                                    </Button>
                                    
                                    <div className="text-[#4C4E64]/[0.68]  text-xs mt-5">อัพโหลดไฟล์ JPG, GIF or PNG. ขนาดไม่เกิน 800K</div>
                                    <input
                                        ref={inputFileRef}
                                        accept="image/*"
                                        hidden
                                        id="avatar-image-upload"
                                        type="file"
                                        onChange={(event) => {
                                            const newImage = event.target?.files?.[0];
                                            if (newImage) { 
        
                                                setFieldValue('image',newImage)
                                                setFieldValue('previewImage',URL.createObjectURL(newImage))
                                            }
       
                                        }}
                                    />
                                </div>

                            </div>
                            
                            <div className="mb-6">
                                <TextField 
                                    select
                                    label="คำนำหน้า *"
                                    name='prefix'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.prefix}
                                
                                    error={!!errors.prefix}
                                    className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                >
                                    {prefix.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div className="mb-6">
                                <TextField
                                    label="ชื่อ *"
                                    name='name'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    error={!!errors.name}
                                    className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                />
                            </div>
                            <div className="mb-6">
                                <TextField
                                    label="นามสกุล *"
                                    name='surname'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.surname}
                                    error={!!errors.surname}
                                    className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                />
                            </div>
                            <div className="mb-6">
                                <TextField 
                                    select
                                    label="ตำแหน่ง *"
                                    name='position'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.position}
                                    error={!!errors.position}
                                    className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                >
                                    {position.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div className="mb-6">
                                <TextField 
                                    
                                    select
                                    label="สิทธิ์การใช้งาน *"
                                    name='permissions'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.permissions}
                                
                                    error={!!errors.permissions}
                                    className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                >
                                    {permissions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div className="mb-6">
                                <FormControl>
                                    <div>สถานะ</div> 
                                    <RadioGroup
                                        row
                                        aria-labelledby=""
                                        name="status"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={ values.status}
                        
                                    >
                                        <FormControlLabel value="1" control={<Radio />} label="เปิดใช้งาน" />
                                        <FormControlLabel value="0" control={<Radio />} label="ปิดใช้งาน" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div className="flex  ">
                                {
                                    values.id ? (
                                        <Button 
                                            variant="outlined" 
                                            className="w-[111px] border-[#FF4D49] text-[#FF4D49] rounded-[8px] text-sm mr-auto  h-[44px]"
                                            onClick={() => {
                                                setOpen(false)
                                                setDeleteOpen(true)
                                            }}
                                        >
                                            ลบ
                                        </Button>
                                    ) : (
                                        <div className="mr-auto"></div>
                                    )
                                }
                                

                                <Button 
                                    variant="outlined" 
                                    className="w-[111px] border-[#6D788D]/[0.5] text-[#6D788D] rounded-[8px] text-sm mr-3  h-[44px]"
                                    onClick={() => {
                                        setOpen(false)
                                    }}
                                >
                                    ยกเลิก
                                </Button>
                                <Button 
                                    variant="contained" 
                                    className="w-[111px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[44px] "
                                    type="submit"
                                >
                                    บันทึก
                                </Button>
                                
                            </div>
                        </form>
                    )}
                </Formik>
                </div>
 
            </Box>
        </Modal>
        </>
    )
}

const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 538,
    bgcolor: 'background.paper',
    border: 0,
    boxShadow: 24,
    p: '15px',
    borderRadius:2,
    overflow:'hidden',
    outline:'none'
};