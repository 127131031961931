import React, { useState } from "react";

import { Layout } from "components/layouts/layout";
import { Login } from "components/pages/login";
import { ListShop } from "components/pages/manage/shop/List";

import { InformationShop } from "components/pages/manage/shop/Information";
import { Contact } from "components/pages/manage/shop/Contact";
import { EmpShop } from "components/pages/manage/shop/EmpShop";
import { BankAccount } from "components/pages/manage/shop/BankAccount";
import { DashboardShop } from "components/pages/manage/shop/Dashboard";


import { HistoryGiveCoupon } from "components/pages/manage/shop/HistoryGiveCoupon";
import { HistoryGiveCouponList } from "components/pages/manage/shop/HistoryGiveCoupon";

import { HistoryReceiveCoupon } from "components/pages/manage/shop/HistoryReceiveCoupon";
import { HistoryReceiveCouponList } from "components/pages/manage/shop/HistoryReceiveCoupon";


import { CampaignList } from "components/pages/campaign/List";
import { CampaignCreate } from "components/pages/campaign/Create";

import { TouristList } from "components/pages/tourist/List";

import { ShopInformation } from "components/layouts/ShopInformation";

import { TouristMenu } from "components/layouts/TouristMenu";
import { TouristInformation } from "components/pages/tourist/Information";
import { CouponHistory } from "components/pages/tourist/couponHistory";
import {
  TouristCampaign,
  TouristCampaignDetail,
} from "components/pages/tourist/Campaign";
import { Mycoupon } from "components/pages/tourist/MyCoupon";
import ProtectedRoute from "./components/ProtectedRoute";
import GuestRoute from "./components/GuestRoute";

import { CouponList } from "components/pages/coupon/List";
import { CouponCreate } from "components/pages/coupon/Create";


import { UsersList } from "components/pages/setting/userList";
import { UserManage } from "components/pages/setting/userManage";

import { Dashboard } from "components/pages/Dashboard";



import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Outlet,
  useParams,
} from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="/login" element={
        <GuestRoute>
          <Login />
        </GuestRoute>
      }>
      </Route>

      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        }
      >
        <Route
          path="/manage/shop/list"
          element={
            <ProtectedRoute>
              <ListShop />
            </ProtectedRoute>
          }
        />

        <Route
          path={`manage/shop/:shopID`}
          element={
            <ProtectedRoute>
              <ShopInformation />
            </ProtectedRoute>
          }
        >
          <Route
            path="contact"
            element={
              <ProtectedRoute>
                <Contact />
              </ProtectedRoute>
            }
          />
          <Route
            path="information"
            element={
              <ProtectedRoute>
                <InformationShop />
              </ProtectedRoute>
            }
          />
          <Route path="emp" element={
            <ProtectedRoute>
              <EmpShop />
            </ProtectedRoute>
          } />

          <Route path="dashboard" element={<DashboardShop />} />
          <Route path="history/givecoupon" element={<HistoryGiveCoupon />} />
          <Route path="history/givecoupon/:campaignID" element={<HistoryGiveCouponList />} />

          <Route path="history/receivecoupon" element={<HistoryReceiveCoupon />} />
          <Route path="history/receivecoupon/:couponID" element={<HistoryReceiveCouponList />} />

          <Route path="bank/account" element={
            <ProtectedRoute>
              <BankAccount />
            </ProtectedRoute>
          } />



        </Route>


        <Route
          path="/tourist"
          element={
            <ProtectedRoute>
              <TouristList />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/tourist/manage/:id`}
          element={
            <ProtectedRoute>
              <TouristMenu />
            </ProtectedRoute>
          }
        >
          <Route
            path="information"
            element={
              <ProtectedRoute>
                <TouristInformation />
              </ProtectedRoute>
            }
          />
          <Route
            path="coupon/history"
            element={
              <ProtectedRoute>
                <CouponHistory />
              </ProtectedRoute>
            }
          />
          <Route
            path="campaign"
            element={
              <ProtectedRoute>
                <TouristCampaign />
              </ProtectedRoute>
            }
          />

          <Route
            path="mycoupon"
            element={
              <ProtectedRoute>
                <Mycoupon />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route
          path={`/tourist/manage/`}
          element={
            <ProtectedRoute>
              <TouristMenu />
            </ProtectedRoute>
          }
        >
          <Route
            path="information"
            element={
              <ProtectedRoute>
                <TouristInformation />
              </ProtectedRoute>
            }
          />
        </Route>


        <Route path="/coupon" element={<CouponList />} />
        <Route path="/coupon/manage/information" element={<CouponCreate />} />
        <Route path="/coupon/manage/:couponID/information" element={<CouponCreate />} />



        <Route
          path="/campaign"
          element={
            <ProtectedRoute>
              <CampaignList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/campaign/manage/:campaignID"
          element={
            <ProtectedRoute>
              <CampaignCreate />
            </ProtectedRoute>
          }
        />

        <Route
          path="/tourist"
          element={
            <ProtectedRoute>
              <TouristList />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/tourist/manage/:id`}
          element={
            <ProtectedRoute>
              <TouristMenu />
            </ProtectedRoute>
          }
        >
          <Route
            path="information"
            element={
              <ProtectedRoute>
                <TouristInformation />
              </ProtectedRoute>
            }
          />
          <Route
            path="coupon/history"
            element={
              <ProtectedRoute>
                <CouponHistory />
              </ProtectedRoute>
            }
          />
          <Route
            path="campaign"
            element={
              <ProtectedRoute>
                <TouristCampaign />
              </ProtectedRoute>
            }
          />
          <Route
            path="campaign/detail/:campaignID"
            element={
              <ProtectedRoute>
                <TouristCampaignDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="mycoupon"
            element={
              <ProtectedRoute>
                <Mycoupon />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route
          path={`/tourist/manage/`}
          element={
            <ProtectedRoute>
              <TouristMenu />
            </ProtectedRoute>
          }
        >
          <Route
            path="information"
            element={
              <ProtectedRoute>
                <TouristInformation />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="/campaign" element={<CampaignList />} />
        <Route path="/campaign/manage/:campaignID" element={<CampaignCreate />} />


        <Route path="/setting/users" element={<UsersList />} />
        <Route path="/setting/users/manage" element={<UserManage />} />
        <Route path="/setting/users/manage/:userID" element={<UserManage />} />

        <Route path="/dashboard" element={<Dashboard />} />

      </Route>

    </Routes >
  );
}

function Home() {
  return <div></div>;
}
function Layoutadmin() {
  return (
    <div className="page">
      <Layout />
      <div className="main-content app-content"></div>
    </div>
  );
}
export default App;
