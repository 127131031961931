import * as couponAction from "../../slices/master/coupon.slice";
import { http } from "../../../utils/http";
import { endPoint } from "../../../constants/server/api";
import { history } from "../../../utils/history";
import { setHeaders, handleUnauthorized } from "../../../utils/helpers";



export const createCoupon = (data) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "post",
        url: `${endPoint.COUPON_URL}`,
        data: data,
        headers: setHeaders({ 'Content-Type': 'multipart/form-data' })
      };

      const result = await http(config);

      return result.data; // Return the response data to use in form submission

    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
      throw error; // Rethrow the error to handle it in form submission
    }
  };

};

export const updateCoupon = (data, id) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "put",
        url: `${endPoint.COUPON_URL}/${id}`,
        data: data,
        headers: setHeaders({ 'Content-Type': 'multipart/form-data' })
      };

      const result = await http(config);

      return result.data; // Return the response data to use in form submission

    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
      throw error; // Rethrow the error to handle it in form submission
    }
  };

};

export const getCouponByCampaignId = (campaignId) => {
  return async (dispatch) => {
    dispatch(couponAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.COUPON_URL}/campaign?campaign_id=${campaignId}`,
        headers: setHeaders(),
      };

      const result = await http(config);
      dispatch(couponAction.fetchDataSuccess(result.data));

    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
      dispatch(couponAction.fetchDataFailure());
    }
  };
};

export const getCouponById = (id) => {
  return async (dispatch) => {
    dispatch(couponAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.COUPON_URL}/${id}`,
        headers: setHeaders()
      };
      const result = await http(config);

      dispatch(couponAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText === "Unauthorized") {
        handleUnauthorized()
      }
    }
  };
};

// general coupon

export const getCouponGeneralPagination = (start_date, end_date, page = 1, perPage = 12, s = "") => {
  return async (dispatch) => {
    dispatch(couponAction.fetchData());

    try {
      const config = {
        method: "get",
        headers: setHeaders(),
        url: `${endPoint.COUPON_GENERAL_URL}`,
        params: {
          start_date,
          end_date,
          page,
          perPage,
          s
        }
      };
      const result = await http(config);

      dispatch(couponAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText === "Unauthorized") {
        handleUnauthorized()
      }

      dispatch(couponAction.fetchDataFailure());
    }
  };
}

export const getCouponGeneralById = (id) => {
  return async (dispatch) => {
    dispatch(couponAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.COUPON_GENERAL_URL}/${id}`,
        headers: setHeaders()
      };
      const result = await http(config);

      dispatch(couponAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText === "Unauthorized") {
        handleUnauthorized()
      }
    }
  };
};

export const createCouponGeneral = (data) => {
  return async (dispatch) => {

    try {
      const config = {
        method: "post",
        url: `${endPoint.COUPON_GENERAL_URL}`,
        data: data,
        headers: setHeaders({ 'Content-Type': 'multipart/form-data' })
      };
      const result = await http(config);

      return result.data; // Return the response data to use in form submission
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      throw error; // Rethrow the error to handle it in form submission
    }
  };

};

export const updateCouponGeneral = (data, id) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "put",
        url: `${endPoint.COUPON_GENERAL_URL}/${id}`,
        data: data,
        headers: setHeaders({ 'Content-Type': 'multipart/form-data' })
      };
      const result = await http(config);

      return result.data; // Return the response data to use in form submission
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }

      throw error; // Rethrow the error to handle it in form submission
    }
  };
};

export const deleteCouponGeneral = (id) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "delete",
        url: `${endPoint.COUPON_GENERAL_URL}/${id}`,
        headers: setHeaders()
      };
      const result = await http(config);

      return result.data; // Return the response data to use in form submission
    } catch (error) {
      if (error.response.statusText === "Unauthorized") {
        handleUnauthorized()
      }

      throw error; // Rethrow the error to handle it in form submission
    }
  };
};

export const downloadExcelCouponGeneral = (start_date, end_date, s = "") => {
  return async (dispatch) => {
    try {
      const config = {
        method: "get",
        url: `${endPoint.COUPON_GENERAL_URL}/download/excel`,
        params: {
          start_date,
          end_date,
          s
        },
        responseType: 'blob', // Important to handle binary data
        headers: setHeaders()
      };

      const result = await http(config);

      // Create a Blob from the response
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;

      // Optionally, get the filename from the response headers
      const contentDisposition = result.headers['content-disposition'];
      let fileName = 'campaignShop.xlsx'; // Default file name

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }

      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up the link element
      link.parentNode.removeChild(link);
    } catch (error) {
      if (error.response.statusText === "Unauthorized") {
        handleUnauthorized()
      }
    }
  };
};

export const setCouponPayload = (payload) => {
  return async (dispatch) => {
    dispatch(couponAction.setPayload(payload));
  }
}