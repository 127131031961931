import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export function QuillCustom ({value,onChange,placeholder}) {

    var Size = ReactQuill.Quill.import('attributors/style/size');
    Size.whitelist = ['12px', '14px', "16px", "18px", "20px", "24px", "32px"];
    ReactQuill.Quill.register(Size, true);

    const Font = ReactQuill.Quill.import('formats/font'); // <<<< ReactQuill exports it
    Font.whitelist = ['Saraban'] ; // allow ONLY these fonts and the default
    ReactQuill.Quill.register(Font, true);


    const  modules = {
        toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'font': ['Saraban']}],
        [{ 'size': [false,'12px', '14px','16px', '18px', '20px', "24px", "32px"] }],
        
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{ 'align': [] }],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        
        ['clean']
        ],
    }

    return (
        <ReactQuill 
            theme="snow" 
            value={value} 
            onChange={onChange} 
            modules={modules}
            placeholder={placeholder}
        />
    )
}