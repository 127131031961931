import { combineReducers } from "@reduxjs/toolkit";

// auth

// master
import getAllTestTableReducer from "./master/getAllTestTable.slice";
import campaignReducer from "./master/campaign.slice";
import surveyReducer from "./master/survey.slice";
import couponReducer from "./master/coupon.slice";
import couponShopReducer from "./master/couponShop.slice";
import campaignShopReducer from "./master/campaignShop.slice";
import campaignCheckInReducer from "./master/campaignCheckIn.slice";
import authReducer from "./master/auth.slice";
import shopReducer from "./master/shop.slice";
import districtReducer from "./master/district.slice";
import provinceReducer from "./master/province.slice";
import shopCategoryReducer from "./master/shopCategory.slice";
import subDistrictReducer from "./master/subDistrict.slice";
import tagReducer from "./master/tag.slice";
import scheduleDayReducer from "./master/scheduleDay.slice";
import userShopReducer from "./master/userShop.slice";
import shopCampaignDistributionReducer from "./master/shopCampaignDistribution.slice";
import shopCouponDistributionReducer from "./master/shopCouponDistribution.slice";

import genderReducer from "./master/gender.slice";
import nationalityReducer from "./master/nationality.slice";
import prefixReducer from "./master/prefix.slice";
// logic

const rootReducer = combineReducers({
  getAllTestTableReducer: getAllTestTableReducer,
  campaignReducer: campaignReducer,
  surveyReducer: surveyReducer,
  couponReducer: couponReducer,
  campaignShopReducer: campaignShopReducer,
  campaignCheckInReducer: campaignCheckInReducer,
  authReducer: authReducer,
  shopReducer: shopReducer,
  districtReducer: districtReducer,
  provinceReducer: provinceReducer,
  subDistrictReducer: subDistrictReducer,
  shopCategoryReducer: shopCategoryReducer,
  tagReducer: tagReducer,
  scheduleDayReducer: scheduleDayReducer,
  userShopReducer: userShopReducer,
  genderReducer: genderReducer,
  nationalityReducer: nationalityReducer,
  prefixReducer: prefixReducer,
  couponShopReducer: couponShopReducer,
  shopCampaignDistributionReducer: shopCampaignDistributionReducer,
  shopCouponDistributionReducer: shopCouponDistributionReducer
});
export default rootReducer;
