import * as shopCategoryAction from "../../slices/master/shopCategory.slice";
import { http } from "../../../utils/http";
import { endPoint } from "../../../constants/server/api";
import { history } from "../../../utils/history";
import { setHeaders ,handleUnauthorized} from "../../../utils/helpers";



export const getShopCategory = () => {
  return async (dispatch) => {
    dispatch(shopCategoryAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.SHOP_CATEGORY_URL}/list`,
        headers: setHeaders(),
      };

      const result = await http(config);
      dispatch(shopCategoryAction.fetchDataSuccess(result.data));
      
    } catch (error) {
      if(error.response.statusText == "Unauthorized"){
        handleUnauthorized()
      }
      dispatch(shopCategoryAction.fetchDataFailure());
    }
  };
};
