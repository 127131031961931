

import React, { useState, useEffect,useRef } from "react";
import { Link, useParams, NavLink } from "react-router-dom";
import Button from '@mui/material/Button';


import Avatar from '@mui/material/Avatar';
import { Formik } from 'formik';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';

import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import {prefix,date,months,years,gender,nationality,user,userinitial,permissions} from "mocks/user";

import Checkbox from '@mui/material/Checkbox';
export function UserManage () { 

    let { userID } = useParams();

    const inputFileRef = useRef(null);
    const [image, setImage] = useState(null);
    
    const [formdata, setFormdata] = useState(userinitial)

    const schema = yup.object().shape({
        prefix: yup.string().required(),
        name: yup.string().required(),
        surname: yup.string().required(),
        username: yup.string().min(6).max(10).required(),
        password: yup.string().required().matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
        phoneNumber: yup.string().required(),
        email: yup.string().email().required(),
        status: yup.number().required(),
        
    });


    

    useEffect(() => {
        if(userID){
            setFormdata(user)
        }
        

    }, []);

    

    return (
        <>
            
            <div className="text-[#4C4E64]/[0.87] text-2xl mb-4 font-medium">ตั้งค่าผู้ใช้งาน</div>
            
                <Formik
                    
                    initialValues={formdata}
                    validationSchema={schema}
                    enableReinitialize
                    onSubmit={async (values) => {

                        alert('save')
                        
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        submitForm
                    }) => (
                        <form onSubmit={handleSubmit} className="max-w-[800px]">
                            <div className="bg-white  rounded-[10px] drop-shadow py-3 mb-4 p-[25px] ">
                                <div className="text-[#4C4E64]/[0.87]  font-medium text-xl mb-5">ข้อมูลการใช้งานระบบ</div>
                                <div className="text-[#4C4E64]/[0.68]  text-base mb-5">รูปโปรไฟล์</div>
                                <div className="flex items-center mb-9">
                                    <Avatar
                                        alt=""
                                        src={image ?  image : values.image}
                                        sx={{ width: 101, height: 101 }}
                                    />
                                    <div className="ml-6">
                                        <Button 
                                            variant="contained" 
                                            className="w-[140px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[38px] mr-3"
                                            onClick={() => inputFileRef.current.click()}
                                        >
                                            อัปโหลดรูปภาพ
                                        </Button>
                                        <Button 
                                            variant="outlined" 
                                            className="w-[62px] border-[#FF4D49]/[0.5] text-[#FF4D49] rounded-[8px] text-sm mr-3  h-[38px]"
                                            onClick={() => {
                                                inputFileRef.current.value = ""
                                                setImage(null)
                                            }}
                                        >
                                            ลบ
                                        </Button>
                                        

                                        <div className="text-[#4C4E64]/[0.68]  text-xs mt-5">รองรับนามสกุล JPG, BMP, 
                                        GIF, PNG ขนาดไม่เกิน 5 MB</div>
                                        <input
                                            ref={inputFileRef}
                                            accept="image/*"
                                            hidden
                                            id="avatar-image-upload"
                                            type="file"
                                            onChange={(event) => {

                                                const newImage = event.target?.files?.[0];
                                                if (newImage) { 
                                                    setImage(URL.createObjectURL(newImage));
                                                    setFieldValue('image',newImage)
                                                }

                                            }}
                                            
                                        />
                                    </div>
                                </div>

                                <div className="grid grid-cols-12 gap-5 mb-6">


                                    <div className="lg:col-span-8 col-span-12">
                                        <div className="mb-2">ชื่อผู้ใช้งาน <span className="text-[#FF0000]">*</span></div>
                                        <TextField
                                            label=""
                                            name='username'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.username}
                                            error={!!errors.username}
                                            className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        />
                                        <div className="text-[#929292] text-sm mt-2">ชื่อผู้ใช้งานควรมี 6-10 ตัวอักษร รองรับเฉพาะภาษาอังกฤษเท่านั้น</div>
                                        
                                    </div>
                                    <div className="lg:col-span-4 col-span-12"></div>
                                    <div className="lg:col-span-8 col-span-12">
                                        <div className="mb-2">รหัสผ่าน <span className="text-[#FF0000]">*</span></div>
                                        <TextField
                                            type="password"
                                            label=""
                                            name='password'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password}
                                            error={!!errors.password}
                                            className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        />
                                        <div className="text-[#929292] text-sm mt-2">รหัสผ่านควรมี 8-16 ตัวอักษร ประกอบตัวอักษรพิมพ์เล็ก พิมพ์ใหญ่ ตัวเลข
                                        อักขระพิเศษ เช่น . - @ #  </div>
                                    </div>

                                </div>
                                <FormControlLabel control={<Checkbox onChange={(e) => {
                                    if(e.target.checked){
                                        setFieldValue('password','P@ssw0rd')
                                    }else{
                                        setFieldValue('password','')
                                    }
                                }} />} label="ใช้รหัสผ่านเริ่มต้น = P@ssw0rd" />
                            </div>

                            <div className="bg-white  rounded-[10px] drop-shadow pt-6 pb-3 mb-4 p-[25px] ">
                                <div className="text-[#4C4E64]/[0.87]  font-medium text-xl mb-5">ข้อมูลผู้ใช้งาน</div>


                                <div className="grid grid-cols-12 gap-7 mb-6">
                                    
                                    <div className="lg:col-span-6 col-span-12">
                                        <div className="mb-2">คำนำหน้า</div>
                                        <TextField 
                                            
                                            select
                                            label=""
                                            name='prefix'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.prefix}
                                        
                                            error={!!errors.prefix}
                                            className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        >
                                            {prefix.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="lg:col-span-6 col-span-12">
          
                                    </div>
                                    <div className="lg:col-span-6 col-span-12">
                                        <div className="mb-2">ชื่อ</div>
                                        <TextField
                                            
                                            label=""
                                            name='name'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                            error={!!errors.name}
                                            className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        />
                                        
                                    </div>
                                    <div className="lg:col-span-6 col-span-12">
                                        <div className="mb-2">นามสกุล</div>
                                        <TextField
                                            
                                            label=""
                                            name='surname'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.surname}
                                            error={!!errors.surname}
                                            className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        />
                                    </div>

                                    <div className="lg:col-span-6 col-span-12">
                                        <div className="mb-2">เบอร์โทร</div>
                                        <TextField
                                            
                                            label=""
                                            name='phoneNumber'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.phoneNumber}
                                            error={!!errors.phoneNumber}
                                            className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        />
                                    </div>
                                    <div className="lg:col-span-6 col-span-12">
                                        <div className="mb-2">อีเมล</div>
                                        <TextField
                                            
                                            label=""
                                            name='email'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            error={!!errors.email}
                                            className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        />
                                    </div>
                                </div>

                                

                                
                            </div>

                            <div className="bg-white  rounded-[10px] drop-shadow pt-6 pb-3 mb-4 p-[25px] ">
                                <div className="text-[#4C4E64]/[0.87]  font-medium text-xl mb-5">สิทธิ์การใช้งาน</div>

                                <div className="grid grid-cols-12 gap-7 mb-6">
                                    <div className="lg:col-span-6 col-span-12">
                                        <div className="mb-2">สิทธิ์การใช้งาน</div>
                                        <TextField 
                                            
                                            select
                                            label=""
                                            name='permissions'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.permissions}
                                        
                                            error={!!errors.permissions}
                                            className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        >
                                            {permissions.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                </div>

                                

                                
                            </div>

                            <div className="bg-white  rounded-[10px] drop-shadow pt-6 pb-1 mb-4 p-[25px] ">
                                <div className="mb-6">
                                    <FormControl>
                                        <div>สถานะ</div> 
                                        <RadioGroup
                                            row
                                            aria-labelledby=""
                                            name="status"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={ values.status}
                            
                                        >
                                            <FormControlLabel value="1" control={<Radio />} label="เปิดใช้งาน" />
                                            <FormControlLabel value="0" control={<Radio />} label="ปิดใช้งาน" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>

                            </div>

                            <div className="flex justify-end mb-7">
                                <Button 
                                    variant="outlined" 
                                    className="w-[111px] border-[#6D788D]/[0.5] text-[#6D788D] rounded-[8px] text-sm mr-3  h-[44px]"
                                    href="/manage/shop/list"
                                >
                                    ยกเลิก
                                </Button>
                                <Button 
                                    variant="contained" 
                                    className="w-[111px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[44px] mr-3"
                                    type="submit"
                                >
                                    บันทึก
                                </Button>
                                    
                            </div>

                        </form>
                    )}
                </Formik>
               
            
        </>
    )
}


