
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import { Link } from 'react-router-dom';
import { MapPointIcon, MapIcon, Ic1Icon, Ic2Icon, Ic3Icon } from "components/fragments/Icon";

export default function CardCampaign2({ item }) {

    return (

        <Card className='pagezoom2 drop-shadow' sx={{ boxShadow: 'none', borderRadius: '10px' }}>
            <CardActionArea className='!flex  !p-[10px]' href={'receivecoupon/' + item.id}>
                <CardMedia
                    component="img"
                    height="106"
                    image={item.image}
                    alt=""
                    className='h-[106px] !w-[106px] rounded-lg'
                />
                <CardContent className='!grow text-left !py-0 !pr-0 '>
                    <div className='text-[#131A22] !text-lg leading-5 mb-2'>{item.name}</div>
                    <div className='text-sm text-[#75788D] leading-5 mb-2'>{item.date}</div>
                    <div className='flex items-center text-sm flex-wrap' >
                        <div className='mr-3'>
                            <div className='text-[#DC148E] flex items-center'><Ic1Icon /> <span className='mx-2'>ทั้งหมด : </span> {item.total}</div>
                        </div>
                        <div className='mr-3'>
                            <div className='text-[#15A44F] flex items-center'><Ic2Icon /> <span className='mx-2'>จ่ายแล้ว : </span> {item.payment_due}</div>
                        </div>
                        <div>
                            <div className='text-[#FF0000]  flex items-center'> <Ic3Icon /> <span className='mx-2'>ค้างรับ :  </span>  {item.paid}</div>
                        </div>
                    </div>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
