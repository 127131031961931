

import DownloadExcel from "components/utils/DownloadExcel";
import {ShopStatus,ShopButtonStatus} from "components/fragments/ShopStatus";
import ic_edit from "assets/svg/ic_edit.svg";
import IconButton from '@mui/material/IconButton';

import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import { Link, useParams, NavLink } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export function CouponHistory () { 

    const data = [
        { 
            id: 1, 
            date: '13/08/2024 11:11', 
            shop: 'โรงคั่วกาแฟพะเยา', 
            couponName: 'ลงทะเบียนที่โรงแรมใช้ส่วนลดที่ร้าน', 
            discount: '100', 
            refCode: 'CPMA24010001', 
        },
        { 
            id: 2, 
            date: '13/08/2024 11:11', 
            shop: 'โรงคั่วกาแฟพะเยา', 
            couponName: 'ลงทะเบียนที่โรงแรมใช้ส่วนลดที่ร้าน', 
            discount: '100', 
            refCode: 'CPMA24010001', 
        },
        { 
            id: 3, 
            date: '13/08/2024 11:11', 
            shop: 'โรงคั่วกาแฟพะเยา', 
            couponName: 'ลงทะเบียนที่โรงแรมใช้ส่วนลดที่ร้าน', 
            discount: '100', 
            refCode: 'CPMA24010001', 
        },
        { 
            id: 4, 
            date: '13/08/2024 11:11', 
            shop: 'โรงคั่วกาแฟพะเยา', 
            couponName: 'ลงทะเบียนที่โรงแรมใช้ส่วนลดที่ร้าน', 
            discount: '100', 
            refCode: 'CPMA24010001', 
        },

    ];

    const [coupon, setCoupon] = useState([]);

    useEffect(() => {
        setCoupon(data)

    }, []);

    const [filterText, setFilterText] = useState("");

    let filteredItems = coupon.filter((item) => {
        const nameMatches =
          item.couponName &&
          item.couponName.toLowerCase().includes(filterText.toLowerCase());
        return nameMatches;
    });



    return (
        <>
            <div className="bg-white  rounded-[10px] drop-shadow py-3 mb-4 p-[25px]">
                <div className="text-[#4C4E64]/[0.87] font-bold text-xl mb-5 mt-2">ประวัติการใช้งานคูปอง</div>
                <div className="d-flex align-items-center  mb-5 flex-wrap">
                    <div className="mr-auto" >
                        <DownloadExcel data={filteredItems} filename="ประวัติการใช้งานคูปอง" />
                    </div>
                    <div className=" ">
                        <input
                            type="text"
                            className="form-control form-st-4 h-[40px] w-[250px]"
                            placeholder="ค้นหา"
                            onKeyUp={(e) => setFilterText(e.target.value)}
                        />
                    </div>
                </div>
                <div className="mb-5">
                    <DataGrid
                        getRowHeight={() => 'auto'}
                        rows={filteredItems}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 5,
                            },
                        },
                        }}
                        pageSizeOptions={[10, 20, 30]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        slotProps={{
                            pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
                        }}
                        sx={{ 
                            borderRadius:0,
                            '.MuiDataGrid-columnHeaderDraggableContainer': { 
                                backgroundColor:'#F5F5F7'
                            },
                            '.MuiDataGrid-topContainer':{
                                fontSize:'16px'
                            },
                            '.MuiDataGrid-cell':{
                                display:'flex',
                                alignItems:'center',
                                paddingTop:'10px',
                                paddingBottom:'10px',
                                fontSize:'16px'
                            },
                            '.MuiDataGrid-footerContainer':{
                                marginRight:'20px'
                            }
                        }}
                    />
                </div>
            </div>
        </>
    )
}

const columns = [
    {
        field: 'date',
        headerName: 'วันที่ใช้',
        headerClassName:'bg-[#F5F5F7]',
        width: 250,
        editable: false,
    },
    {
        field: 'shop',
        headerName: 'ร้านที่ใช้',
        headerClassName:'bg-[#F5F5F7]',
        flex:1,
        width: 250,
        editable: false,

    },
    {
        field: 'couponName',
        headerName: 'ชื่อคูปอง',
        headerClassName:'bg-[#F5F5F7]',
        width: 220,
        flex:1,
        editable: false,
    },
    {
        field: 'discount',
        headerName: 'ส่วนลดที่ได้รับ',
        headerClassName:'bg-[#F5F5F7]',
        sortable: false,
        width: 220,
        renderCell: ({ row }) => (
            <>{row.discount}.-</>
        ),
    },

    

    {
        field: 'refCode',
        headerName: 'อ้างอิง',
        headerClassName:'bg-[#F5F5F7]',
        sortable: false,
        width: 220,

    },
];
