
import React, { useState, useEffect, useRef } from "react";
import Button from '@mui/material/Button';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';

import MenuItem from '@mui/material/MenuItem';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { campaign } from "mocks/shop";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DocumentTextIcon, C1Icon, C2Icon, C3Icon } from "components/fragments/Icon";

import dayjs from 'dayjs';

import CardCampaign2 from "components/layouts/CardCampaign2";

import Chip from '@mui/material/Chip';
import ic_trash from "assets/icon/solar_trash-bin-trash-outline.svg";
import { Password } from "@mui/icons-material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import ic_edit from "assets/svg/ic_edit.svg";
import IconButton from '@mui/material/IconButton';
import { DataGrid } from "@mui/x-data-grid";
import DownloadExcel from "components/utils/DownloadExcel";

import FormatDate from "components/utils/FormatDate";
import { Link, useParams, NavLink, useNavigate } from "react-router-dom";
import thaiDateRange from "utils/thaiDateRange";
import { useAppDispatch, useAppSelector } from "../../../../redux/store/configureStore";
import { getShopCouponDistributionPagination, getShopCouponDistributionUserPagination, downloadExcelShop } from "../../../../redux/handlers/master/shopCouponDistribution";
import { getCouponById } from "../../../../redux/handlers/master/coupon";

export function HistoryReceiveCoupon() {
    let { shopID } = useParams();
    const dispatch = useAppDispatch();
    const shopCouponDistributionReducer = useAppSelector((state) => state.shopCouponDistributionReducer);
    const [shopCouponList, setShopCouponList] = useState([]);

    useEffect(() => {
        dispatch(getShopCouponDistributionPagination(shopID, 1, 9999));
    }, [shopID, dispatch]);

    useEffect(() => {
        if (shopCouponDistributionReducer.data != null && shopCouponDistributionReducer.data.data) {
            const data = shopCouponDistributionReducer.data.data.data;

            if (data && data.length > 0) {
                let campaignData = []

                for (const element of data) {
                    if (element.coupon != null) {
                        campaignData.push({
                            id: element?.coupon_id,
                            image: element?.coupon?.campaign?.image_path,
                            name: element?.coupon?.campaign?.name,
                            date: thaiDateRange(element?.coupon?.campaign?.start_date, element?.coupon?.campaign?.end_date, null),
                            total: element?.coupon?.total,
                            paid: element?.coupon?.paid,
                            payment_due: element?.coupon?.payment_due,
                        })
                    }
                }

                setShopCouponList(campaignData);
            }
        }
    }, [shopCouponDistributionReducer]);

    return (
        <>
            <div className="text-2xl mb-5 font-semibold text-[#4C4E64]/[0.87]">แคมเปญ</div>
            {shopCouponList.map((item, index) => (
                <div className="mb-3" key={index}>
                    <CardCampaign2 item={item} />
                </div>
            ))}
        </>
    )
}

export function HistoryReceiveCouponList() {
    let { couponID, shopID } = useParams();
    const dispatch = useAppDispatch();
    const shopCouponDistributionReducer = useAppSelector((state) => state.shopCouponDistributionReducer);
    const couponReducer = useAppSelector((state) => state.couponReducer);
    const [shopCouponList, setShopCouponList] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [totalRows, setTotalRows] = useState(0);
    const [filterText, setFilterText] = useState("");
    const [campaignName, setCampaignName] = useState("");
    const setShopCouponData = (data) => {
        if (data && data.length > 0) {
            const couponData = data.map((element) => ({
                id: element.id,
                couponCode: element?.coupon?.code,
                couponName: element?.coupon?.coupon_name,
                campaignName: null,
                couponRecipient: `${element?.userTravellerCoupon?.userTraveller?.first_name} ${element?.userTravellerCoupon?.userTraveller?.last_name}`,
                date: FormatDate(element?.created_at),
                couponValue: `${element?.coupon?.discount_value} ${(element?.coupon?.discount_type == 'percent') ? '%' : 'บาท'}`,
            }));

            setShopCouponList(couponData);
        } else {
            setShopCouponList([]);
        }
    };
    const handlePageChange = (newPage) => {
        setPage(newPage.page + 1);
        setPageSize(newPage.pageSize);
    };
    const handleSearch = (e) => {
        setFilterText(e.target.value);
    };
    const download = () => {
        dispatch(downloadExcelShop(shopID, couponID))
    }
    const columns = [
        {
            field: 'couponCode',
            headerName: 'รหัสคูปอง',
            headerClassName: 'bg-[#F5F5F7]',
            width: 150,
            editable: false,
            renderCell: ({ row }) => (
                <div>{row.couponCode}</div>
            ),
        },
        {
            field: 'couponName',
            headerName: 'ชื่อคูปอง',
            headerClassName: 'bg-[#F5F5F7]',
            flex: 1,
            width: 150,
            editable: false,
        },
        {
            field: 'couponRecipient',
            headerName: 'ผู้รับคูปอง',
            headerClassName: 'bg-[#F5F5F7]',
            width: 110,
            flex: 1,
            editable: false,
        },
        {
            field: 'date',
            headerName: 'วันที่รับสิทธิ์',
            headerClassName: 'bg-[#F5F5F7]',
            sortable: false,
            width: 160,

        },
        {
            field: 'couponValue',
            headerName: 'มูลค่าคูปอง',
            headerClassName: 'bg-[#F5F5F7]',
            sortable: false,
            width: 160,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <>
                    <div className="text-[#31A7DE]">{row.couponValue}</div>
                </>
            ),
        },
        {
            field: 'id',
            headerName: 'จัดการ',
            headerClassName: 'bg-[#F5F5F7]',
            sortable: false,
            width: 160,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <>
                </>
            ),
        },
    ];

    useEffect(() => {
        dispatch(getCouponById(couponID))
    }, [couponID, dispatch]);

    useEffect(() => {
        if (couponReducer.data != null) {
            const data = couponReducer?.data.data;

            if (data) {
                setCampaignName(data.campaign.name)
            }
        }
    }, [couponReducer]);

    useEffect(() => {
        dispatch(getShopCouponDistributionUserPagination(shopID, couponID, page, pageSize, filterText));
    }, [shopID, couponID, page, pageSize, filterText, dispatch]);

    useEffect(() => {
        if (shopCouponDistributionReducer.data != null && shopCouponDistributionReducer.data.data) {
            const data = shopCouponDistributionReducer.data.data.data;
            setShopCouponData(data);
            setTotalRows(shopCouponDistributionReducer?.data?.data?.meta?.total);
        }
    }, [shopCouponDistributionReducer]);

    return (
        <>
            <div className="bg-white rounded-[15px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden pt-[15px]">
                <div className="px-[10px] py-[10px]">
                    <div className="text-[#4C4E64]/[0.87] text-xl  px-4 font-semibold mb-5">{campaignName}</div>
                    <div className="d-flex align-items-center px-4 mb-3 flex-wrap">
                        <div className="mr-auto" >
                            <button className='btn btn-export-st-1 me-auto' onClick={() => download()} disabled={(totalRows == 0)}>Export</button>
                        </div>
                        <div className="me-3 ">
                            <input
                                type="text"
                                className="form-control form-st-4 h-[38px] w-[250px]"
                                placeholder="ค้นหา"
                                onKeyUp={handleSearch}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <DataGrid
                        getRowHeight={() => 'auto'}
                        rows={shopCouponList}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        rowCount={totalRows}
                        paginationModel={{ page: page - 1, pageSize: pageSize }}
                        paginationMode="server"
                        onPaginationModelChange={handlePageChange}
                        pageSizeOptions={[10, 20, 30]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        slotProps={{
                            pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
                        }}
                        sx={{
                            borderRadius: 0,
                            '.MuiDataGrid-columnHeaderDraggableContainer': {
                                backgroundColor: '#F5F5F7'
                            },
                            '.MuiDataGrid-topContainer': {
                                fontSize: '16px'
                            },
                            '.MuiDataGrid-cell': {
                                display: 'flex',
                                alignItems: 'center',
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                fontSize: '16px'
                            }
                        }}
                    />
                </div>
            </div>
        </>
    );
}