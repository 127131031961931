

import React, { useState, useEffect } from "react";

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export function Mycoupon () { 

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const data = [
        { 
            id: 1, 
            couponType: 'คูปองส่วนลด', 
            couponDiscount: '10%',
            couponImage:"/images/coupon/c1.png",
            couponMinimum: '0', 
            couponExpire: '22 พ.ย. 2565', 
            couponStatus:1
        },
        { 
            id: 2, 
            couponType: 'คูปองส่วนลด', 
            couponDiscount: '10%',
            couponImage:"/images/coupon/c1.png",
            couponMinimum: '0', 
            couponExpire: '22 พ.ย. 2565', 
            couponStatus:2
        },
        { 
            id: 3, 
            couponType: 'คูปองส่วนลด', 
            couponDiscount: '10%',
            couponImage:"/images/coupon/c1.png",
            couponExpire: '22 พ.ย. 2565', 
            couponMinimum: '0', 
            couponStatus:3
        }
    ]

    const [coupon, setCoupon] = useState([]);
    const [couponExpire, setCouponExpire] = useState([]);


    
    useEffect(() => {
        
        let ucoupon = data.filter((item) => { return  item.couponStatus === 1 })
        let ucouponExpire = data.filter((item) => { return  item.couponStatus !== 1 })
        setCoupon(ucoupon)
        setCouponExpire(ucouponExpire)



    }, [data]);


    return (

            <Box sx={{ width: '100%' }} className="mx-6">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label=""
                >
                    <Tab label="คูปองของฉัน" className="text-lg" {...a11yProps(0)} />
                    <Tab label="ใช้แล้ว/หมดอายุ" className="text-lg" {...a11yProps(1)}  />
                </Tabs>

                <TabPanel value={value} index={0}>

                
                    {coupon.map((item,index) => 

                        <div className="w-[437px] h-[188px] drop-shadow-sm flex px-2 py-3  mb-3" key={index} style={{
                            backgroundImage:'url(/images/coupon/Subtract.png)',
                            backgroundSize:'100% 100% '
                        }}>
                            <img src={item.couponImage} className="w-[168px] h-[160px] rounded-lg object-cover" />
                            <div className="grow pl-7 py-0">
                                <div className="mb-1">{item.couponType}</div>
                                <div className="text-[#31A7DE] text-3xl font-bold ml-4 mb-2">{item.couponDiscount}</div>
                                <div className="text-[#131A22] text-sm mb-1">เงื่อนไขซื้อขั้นต่ำ  <span className="ml-5">฿{item.couponMinimum} บาท</span></div>
                                <div className="text-[#131A22] text-sm font-bold text-lg  mb-3">หมดเขต {item.couponExpire}</div>
                                <div className="text-right pr-5">
                                    <Button variant="contained"
                                    className="bg-[#31A7DE] text-sm text-white ml-auto"
                                    onClick={() => {
                                        alert('ตัดใช้คูปอง')
                                    }}
                                    >ตัดใช้คูปอง</Button>
                                </div>
                                
                            </div>

                        </div>
                    )}
                    
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {couponExpire.map((item,index) => 

                        <div className="w-[437px] h-[188px] drop-shadow-sm flex px-2 py-3 mb-3" key={index} style={{
                            backgroundImage:'url(/images/coupon/Subtract.png)',
                            backgroundSize:'100% 100% '
                        }}>
                            <img src={item.couponImage} className="w-[168px] h-[160px] rounded-lg object-cover" />
                            <div className="grow pl-7 py-0">
                                <div className="mb-1">{item.couponType}</div>
                                <div className="text-[#75788D] text-3xl font-bold ml-4 mb-2">{item.couponDiscount}</div>
                                <div className="text-[#131A22] text-sm mb-1">เงื่อนไขซื้อขั้นต่ำ  <span className="ml-5">฿{item.couponMinimum} บาท</span></div>
                                <div className="text-[#131A22] text-sm font-bold text-lg  mb-4">หมดเขต {item.couponExpire}</div>
                                <div className="text-right pr-5">
                                
                                    {
                                        item.couponStatus == 2 ? (
                                            <div className="text-xl font-bold text-[#75788D]">ใช้เเล้ว</div>
                                        ) : (
                                            item.couponStatus == 3 ? (
                                                <div className="text-xl font-bold text-[#F24236]" >หมดอายุ</div>
                                            ) : ''
                                        )
                                        
                                    }
        
                                </div>
                                
                            </div>

                        </div>
                        )}
                </TabPanel>
            </Box>

    )
}

function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        className="mt-5 "
        {...other}
      >
        {value === index && (
          <Box>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };