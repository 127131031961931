import * as shopCampaignDistributionAction from "../../slices/master/shopCampaignDistribution.slice";
import { http } from "../../../utils/http";
import { history } from "../../../utils/history";
import { setHeaders, handleUnauthorized } from "../../../utils/helpers";
import { endPoint } from "../../../constants/server/api";


export const getShopCampaignDistributionPagination = (shop_id, page = 1, perPage = 12) => {
  return async (dispatch) => {
    dispatch(shopCampaignDistributionAction.fetchData());
    try {
      const config = {
        method: "get",
        headers: setHeaders(),
        url: `${endPoint.SHOP_CAMPAIGN_DISTRIBUTION_URL}/pagination`,
        params: {
          shop_id,
          page,
          perPage,
        }
      };
      const result = await http(config);

      dispatch(shopCampaignDistributionAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
      dispatch(shopCampaignDistributionAction.fetchDataFailure());
    }
  };
};

export const getShopCampaignDistributionUserPagination = (shop_id, campaign_id, page = 1, perPage = 12, s) => {
  return async (dispatch) => {
    dispatch(shopCampaignDistributionAction.fetchData());
    try {
      const config = {
        method: "get",
        headers: setHeaders(),
        url: `${endPoint.SHOP_CAMPAIGN_DISTRIBUTION_URL}/pagination/user`,
        params: {
          page,
          perPage,
          shop_id,
          campaign_id,
          s
        }
      };
      const result = await http(config);

      dispatch(shopCampaignDistributionAction.fetchDataSuccess(result.data));
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
      dispatch(shopCampaignDistributionAction.fetchDataFailure());
    }
  };
};

export const downloadExcelShop = (shop_id, campaign_id) => {
  return async (dispatch) => {
    try {
      const config = {
        method: "get",
        url: `${endPoint.SHOP_CAMPAIGN_DISTRIBUTION_URL}/download/excel`,
        params: {
          campaign_id,
          shop_id
        },
        responseType: 'blob', // Important to handle binary data
        headers: setHeaders()
      };
      const result = await http(config);

      // Create a Blob from the response
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;

      // Optionally, get the filename from the response headers
      const contentDisposition = result.headers['content-disposition'];
      let fileName = 'shopShop.xlsx'; // Default file name

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);

        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }

      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up the link element
      link.parentNode.removeChild(link);
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
        handleUnauthorized()
      }
    }
  };
};

