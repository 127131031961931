import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //campaign
  data: null,
  isFetching: false,
  isError: false,
  // campaignType
  campaignType: null,
  iscampaignTypeFetching: false,
  iscampaignTypeError: false,
};
const campaignSlice = createSlice({
  name: 'campaign',
  initialState: initialState,
  reducers: {
     // campaign
    fetchData: (state) => {
      state.isFetching = true;
      state.data = null;
      state.isError = false;
    },
    fetchDataSuccess: (state, action) => {
      return {
        ...state,
        isFetching: false,
        data: action.payload,
      };
    },
    fetchDataFailure: (state) => {
      return { ...state, isFetching: false, isError: true };
    },
    // campaignType
    fetchDataCampaginType: (state) => {
      state.iscampaignTypeFetching = true;
      state.campaignType = null;
      state.iscampaignTypeError = false;
    },
    // campaignType
    fetchDataCampaginTypeSuccess: (state, action) => {
      return {
        ...state,
        iscampaignTypeFetching: false,
        campaignType: action.payload,
      };
    },
    fetchDataCampaginTypeFailure: (state) => {
      return { ...state, iscampaignTypeFetching: false, iscampaignTypeError: true };
    },
    fetchDataCountCampaignProcess: (state) => {
      state.isFetching = true;
      state.count = null;
      state.isError = false;
    },
    fetchDataCountCampaignProcessSuccess: (state,action) => {
      return {
        ...state,
        isFetching: false,
        count: action.payload.data,
      };
    },
    fetchDataCountCampaignProcessFailure: (state) => {
      return { ...state, isFetching: false, isError: true };
    },
    resetState: () => initialState,
  },
});

export const { 
  fetchData, 
  fetchDataSuccess, 
  fetchDataFailure, 
  resetState,
  fetchDataCampaginType,
  fetchDataCampaginTypeSuccess,
  fetchDataCampaginTypeFailure,
  fetchDataCountCampaignProcess, 
  fetchDataCountCampaignProcessSuccess,
  fetchDataCountCampaignProcessFailure
} =
  campaignSlice.actions;
export default campaignSlice.reducer;
