
import DownloadExcel from "components/utils/DownloadExcel";
import {ShopStatus,ShopButtonStatus} from "components/fragments/ShopStatus";
import ic_edit from "assets/svg/ic_edit.svg";
import IconButton from '@mui/material/IconButton';

import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import { Link, useParams, NavLink } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


export function TouristList () {


    const data = [
        { 
            id: 1, 
            touristCode: 'C0001', 
            touristName: 'นายสถาปนิค บัวพิทักษ์', 
            touristImage: '/images/tourist/t1.png', 
            touristProvince: 'อุดรธานี', 
            touristTelephoneNumber: '082 190 9009', 
        },
        { 
            id: 2, 
            touristCode: 'C0002', 
            touristName: 'นางสาวกัลยาณี บัวพิทักษ์', 
            touristImage: '/images/tourist/t2.png', 
            touristProvince: 'หนองคาย', 
            touristTelephoneNumber: '082 190 9009', 
        },
        { 
            id: 3, 
            touristCode: 'C0003', 
            touristName: 'นายมานพ กลิ่นปทุม', 
            touristImage: '/images/tourist/t3.png', 
            touristProvince: 'ขอนแก่น', 
            touristTelephoneNumber: '082 190 9009', 
        },
        { 
            id: 4, 
            touristCode: 'C0004', 
            touristName: 'นางสาวปิ่นมณี ศรีใจ', 
            touristImage: '/images/tourist/t4.png', 
            touristProvince: 'กรุงเทพ', 
            touristTelephoneNumber: '082 190 9009', 
        },
    ];

    const [tourist, setTourist] = useState([]);

    useEffect(() => {
        setTourist(data)

    }, []);


    const [filterText, setFilterText] = useState("");

    let filteredItems = tourist.filter((item) => {
        const nameMatches =
          item.touristName &&
          item.touristName.toLowerCase().includes(filterText.toLowerCase());
        return nameMatches;
    });


    return (
        <>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>

            <div className="text-[#4C4E64]/[.87] text-2xl font-semibold my-5">รายชื่อนักท่องเที่ยว</div>


            <div className="bg-white rounded-[15px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden pt-[15px]">
                <div className="px-[15px] py-[10px]">
                    <div className="text-[#4C4E64]/[.87] text-2xl  px-4 font-semibold mb-5">รายชื่อนักท่องเที่ยว</div>
                    <div className="d-flex align-items-center px-4 mb-3 flex-wrap">
                        <div className="mr-auto" >
                            <DownloadExcel data={filteredItems} filename="รายชื่อนักท่องเที่ยว" />
                        </div>


                        <div className="me-3 ">
                            <input
                                type="text"
                                className="form-control form-st-4 h-[38px] w-[250px]"
                                placeholder="ค้นหาเพิ่มรายชื่อนักท่องเที่ยว"
                                onKeyUp={(e) => setFilterText(e.target.value)}
                            />
                        </div>
                        <Link
                        to="/tourist/manage/information"
                        className="btn px-4 h-[38px] text-white  bg-[#31A7DE]"
                        
                        >
                            เพิ่มรายชื่อนักท่องเที่ยว
                        </Link>
                    </div>
                </div>
                <div>
                    <DataGrid
                        getRowHeight={() => 'auto'}
                        rows={filteredItems}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 5,
                            },
                        },
                        }}
                        pageSizeOptions={[10, 20, 30]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        slotProps={{
                            pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
                        }}
                        sx={{ 
                            borderRadius:0,
                            '.MuiDataGrid-columnHeaderDraggableContainer': { 
                                backgroundColor:'#F5F5F7'
                            },
                            '.MuiDataGrid-topContainer':{
                                fontSize:'16px'
                            },
                            '.MuiDataGrid-cell':{
                                display:'flex',
                                alignItems:'center',
                                paddingTop:'10px',
                                paddingBottom:'10px',
                                fontSize:'16px'
                            }
                        }}
                    />
                </div>
            </div>
            
            
        </>
    );
}


const columns = [
    {
        field: 'touristCode',
        headerName: 'รหัสนักท่องเที่ยว',
        headerClassName:'bg-[#F5F5F7]',
        width: 150,
        editable: false,

        renderCell: ({ row }) => (
            <div className="text-[#31A7DE]">{row.touristCode}</div>
        ),
    },
    {
        field: 'touristName',
        headerName: 'ชื่อ-นามสกุล',
        headerClassName:'bg-[#F5F5F7]',
        flex:1,
        width: 150,
        editable: false,
        renderCell: ({ row }) => (
            <div className="flex items-center">
                <img src={row.touristImage} className="w-10 h-10 rounded-full" />
                <div className="ml-3">{row.touristName}</div>
            </div>
        ),
    },
    {
        field: 'touristProvince',
        headerName: 'จังหวัดภูมิลำเนา',
        headerClassName:'bg-[#F5F5F7]',
        width: 110,
        flex:1,
        editable: false,
    },
    {
        field: 'touristTelephoneNumber',
        headerName: 'เบอร์โทรศัพท์',
        headerClassName:'bg-[#F5F5F7]',
        sortable: false,
        width: 160,
    },

    {
        field: 'id',
        headerName: 'จัดการ',
        headerClassName:'bg-[#F5F5F7]',
        sortable: false,
        width: 160,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) => (
            <>
                <IconButton href={`/tourist/manage/${row.id}/information` } className="w-[30px] mr-3 h-[30px] p-1" style={{
                    background:'#E3E8F0',
                }} >
                    <img src={ic_edit} alt="" />
                </IconButton>
                <ShopButtonStatus status={row.shopStatus}  href={`/manage/shop/${row.id}/information` }/>
            </>
            
        ),
    },
];

const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      นักท่องเที่ยว
    </Link>,
    <Typography key="3" sx={{ color: 'text.primary' }}>
      รายชื่อนักท่องเที่ยว
    </Typography>
];

