


import {ShopStatus,ShopButtonStatus} from "components/fragments/ShopStatus";

import { ModalAllder } from "components/layouts/ModalAllder";


import React, { useState, useEffect } from "react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import ipiechart from "assets/svg/PieChart.svg";
import icard from "assets/svg/card.svg";
import ihistory from "assets/svg/History.svg";
import ishop2 from "assets/svg/Shop2.svg";
import itag from "assets/svg/tag.svg";
import iusercircle from "assets/svg/UserCircle.svg";
import iuserid from "assets/svg/UserId.svg";
import UserIdActive from "assets/svg/UserId.svg";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { Formik } from 'formik';
import * as yup from 'yup';
import IconButton from '@mui/material/IconButton';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

import {
    Link,
    useParams,
    NavLink,
    Outlet,
    useNavigate,
  } from "react-router-dom";


export function TouristMenu () { 

    let { id } = useParams();
    console.log(id)


    const [shop, setshop] = useState({
        id: 1, 
        shopCode: 'RCPY000', 
        shopName: 'So Good Phayao', 
        shopImage: '/images/shop/shop1.png', 
        shopAddress: '222 หมู่ 17 ตำบลบ้านต๋อม อ.เมือง จ.พะเยา 56000', 
        shopType: 'ร้านอาหาร', 
        shopOpen: '11.00 น. - 24.00 น.', 
        shopTelephoneNumber: '082 190 9009', 
        shopStatus: 0, 
        dateRegister:"01/01/61 12:21", 
        dateApprove:"-", 
        approver:"-", 
        
    });

    
    const [open, setOpen] = React.useState(false);
    


    return (
        <>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>
            <div className="text-[#4C4E64]/[.87] text-2xl font-semibold my-5">จัดการนักท่องเที่ยว</div>

            <div className="flex">
                <div className=" w-[272px] mr-4">
                    <div className="bg-white  rounded-[10px] drop-shadow py-3 mb-4">
                        <div className="px-[15px] mb-4">จัดการนักท่องเที่ยว</div>
                        <ul className="text-[#75788D]">
                            <li className="mb-5">

                                <NavLink
                                    to="information"
                                    className={({ isActive, isPending }) =>
                                        isActive ? "text-[#31a7de]" : ""
                                    }
                                    >
                                    <div className="pl-[15px] flex items-center hover:text-[#31a7de]">
                                        <img className="mr-3" src={iuserid} alt="" />
                                        ข้อมูลส่วนตัว
                                    </div>
                                </NavLink>
                            </li>
                            {
                                id ? (
                                    <>
                                         <li className="mb-5">
                                            <NavLink
                                                to="coupon/history/"
                                                className={({ isActive, isPending }) =>
                                                    isActive ? "text-[#31a7de]" : ""
                                                }
                                            >
                                                <div className="pl-[15px] flex items-center hover:text-[#31a7de]">
                                                    <img className="mr-3" src={ihistory} alt="" />
                                                    ประวัติการใช้คูปอง
                                                </div>
                                            </NavLink>


                                        </li>
                                        <li className="mb-5">
                                            <NavLink
                                                to="campaign"
                                                className={({ isActive, isPending }) =>
                                                    isActive ? "text-[#31a7de]" : ""
                                                }
                                                >
                                                <div className="pl-[15px] flex items-center hover:text-[#31a7de]">
                                                <img className="mr-3" src={itag} alt="" />
                                                    แคมเปญที่เข้าร่วม
                                                </div>
                                            </NavLink>
                                        </li>
                                        <li className="mb-5">
                                            <NavLink
                                                to="mycoupon"
                                                className={({ isActive, isPending }) =>
                                                    isActive ? "text-[#31a7de]" : ""
                                                }
                                                >
                                                <div className="pl-[15px] flex items-center hover:text-[#31a7de]">
                                                <img className="mr-3" src={icard} alt="" />
                                                    คูปองของฉัน
                                                </div>
                                            </NavLink>
                                        </li>
                                    </>
                                ) : ''
                            }
                           

                            
                        </ul>
                    </div>
         
                </div>
                <div className="grow">


                    <Outlet />
                </div>
            </div>
 
            <div className="py-2"></div>
        </>
    )
}


const breadcrumbs = [
    <NavLink
        to={`/tourist`}
        className={({ isActive, isPending }) =>
            isActive ? "text-[#31a7de]" : ""
        }
        >
        <div className=" flex items-center hover:text-[#31a7de]">
        นักท่องเที่ยว
        </div>
    </NavLink>,
    
    <Typography key="3" sx={{ color: 'text.primary' }}>
      จัดการนักท่องเที่ยว
    </Typography>
];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: 0,
    boxShadow: 24,
    p: 0,
    borderRadius:2,
    overflow:'hidden'
};