
export const user = {
    "username":"tttttttt",
    "code":"C0001",
    "image":"/images/Ellipse.png",
    "prefix":"นาง",
    "name":"สุจิรา",
    "surname":"งานดี",
    "phoneNumber":"081-123-6789",
    "email":"ex@gmail.com",
    "status":1,
    "permissions":1
};

export const userinitial = {
    "code":"",
    "image":"",
    "prefix":"",
    "name":"",
    "surname":"",
    "phoneNumber":"",
    "status":"",
    "permissions":""
};

export const prefix = [
    {
      value: 'นาย',
      label: 'นาย',
    },
    {
      value: 'นาง',
      label: 'นาง',
    },
    {
      value: 'นางสาว',
      label: 'นางสาว',
    }
];

export const permissions = [
    {
      value: 1,
      label: 'แอดมิน',
    },
];

export const position = [
  {
    value: 1,
    label: 'ผู้จัดการ',
  },
];
export const date = [
    { value: 1,label: 1},
    { value: 2,label: 2},
    { value: 3,label: 3},
    { value: 4,label: 4},
    { value: 5,label: 5},
    { value: 6,label: 6},
    { value: 7,label: 7},
    { value: 8,label: 8},
    { value: 9,label: 9},
    { value: 10,label: 10},
    { value: 11,label: 11},
    { value: 12,label: 12},
    { value: 13,label: 13},
    { value: 14,label: 14},
    { value: 15,label: 15},
    { value: 16,label: 16},
    { value: 17,label: 17},
    { value: 18,label: 18},
    { value: 19,label: 19},
    { value: 20,label: 20},
    { value: 21,label: 21},
    { value: 22,label: 22},
    { value: 23,label: 23},
    { value: 24,label: 24},
    { value: 25,label: 25},
    { value: 26,label: 26},
    { value: 27,label: 27},
    { value: 28,label: 28},
    { value: 29,label: 29},
    { value: 30,label: 30},
    { value: 31,label: 31},

];

export const months = [
    { value: 'มกราคม',label: 'มกราคม'},
    { value: 'กุมภาพันธ์',label: 'กุมภาพันธ์'},
    { value: 'มีนาคม',label: 'มีนาคม'},
    { value: 'เมษายน',label: 'เมษายน'},
    { value: 'พฤษภาคม',label: 'พฤษภาคม'},
    { value: 'มิถุนายน',label: 'มิถุนายน'},
    { value: 'กรกฎาคม',label: 'กรกฎาคม'},
    { value: 'สิงหาคม',label: 'สิงหาคม'},
    { value: 'กันยายน',label: 'กันยายน'},
    { value: 'ตุลาคม',label: 'ตุลาคม'},
    { value: 'พฤศจิกายน',label: 'พฤศจิกายน'},
    { value: 'ธันวาคม',label: 'ธันวาคม'},
]

export const years = () => {


    let currentYear = new Date().getFullYear();
    let yearLength = 100;

    let y = [];
    for(var i = currentYear;i > (currentYear - yearLength) ;i--){
        var o = { value: i,label: i}
        y.push(o)
    }

    return y


}

export const gender = [
    {
      value: 'M',
      label: 'ชาย',
    },
    {
      value: 'F',
      label: 'หญิง',
    }
];

export const nationality = [
    {
        value: 'th',
        label: 'ไทย',
    }
]

export const shoptype = [
  {
      value: 1,
      label: 'กาแฟ/cafe',
  }
]

export const infoinitial = {
  "shopCode":"",
  "image":"",
  "imagecover":"",
  "shopname":"",
  "shopdetail":"",
  "shoptype":"",
  "address":"",
  "subdistrict":"",
  "district":"",
  "province":"",
  "zipcode":"",
  "uidline":"",
  "phoneNumber":"",
  "tags":[],
  "lat":"",
  "lng":""
};

export const info = {
  "shopCode":"RCPY001",
  "image":"/images/shop/logo1.png",
  "imagecover":"/images/shop/shopcover.png",
  "shopname":"So Good Phayao",
  "shopdetail":"รายละเอียดร้านค้า",
  "shoptype":1,
  "address":"ชื่อเลขที่บริษัท ถนน และอื่นๆ",
  "subdistrict":"ดอกไม้",
  "district":"ประเวศ",
  "province":"กรุงเทพมหานคร",
  "zipcode":"10250",
  "uidline":"sam.pat",
  "phoneNumber":"081-123-6789",
  "tags":["ของกินเมืองพะเยา","ร้านอร่อยบอกด้วย","คาเฟ่"],
  "lat":16.0545427,
  "lng":103.6523976
};
