const domain = process.env.REACT_APP_API_URL;

export { domain };

export const apiUrl = process.env.REACT_APP_API_URL;
export const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY

export const apiImageUrl = process.env.REACT_APP_API_IMAGE_URL;

export const YES = "YES";
export const NO = "NO";
export const OK = "OK";
export const TOKEN = "TOKEN";
export const VER = process.env.VITE_VERSION;

export const endPoint = {
  TEST_TABLE_URL: `${apiUrl}test-table`,
  CAMPAIGN_URL: `${apiUrl}campaign`,
  COUNT_CAMPAIGN_PROCESS_URL: `${apiUrl}campaign/count_campaign_process`,
  CAMPAIGN_TYPE_URL: `${apiUrl}campaign-type`,
  SURVEY_URL: `${apiUrl}survey`,
  COUPON_URL: `${apiUrl}coupon`,
  COUPON_GENERAL_URL: `${apiUrl}coupon-general`,
  COUPON_SHOP_URL: `${apiUrl}coupon-shop`,
  CAMPAIGN_SHOP_URL: `${apiUrl}campaign-shop`,
  SHOP_URL: `${apiUrl}shop`,
  SHOP_CAMPAIGN_DISTRIBUTION_URL: `${apiUrl}shop-campaign-distribution`,
  SHOP_COUPON_DISTRIBUTION_URL: `${apiUrl}shop-coupon-distribution`,
  CAMPAIGN_CHECK_IN_URL: `${apiUrl}campaign-check-in`,
  AUTH_URL: `${apiUrl}user-admin`,
  DISTRICT_URL: `${apiUrl}district`,
  SUB_DISTRICT_URL: `${apiUrl}sub-district`,
  PROVINCE_URL: `${apiUrl}province`,
  SHOP_CATEGORY_URL: `${apiUrl}shop-category`,
  TAG_URL: `${apiUrl}tag`,
  SCHEDULE_DAY_URL: `${apiUrl}schedule-day`,
  USER_SHOP_URL: `${apiUrl}user-shop`,
  GENDER_URL: `${apiUrl}gender`,
  NATIONALITY_URL: `${apiUrl}nationality`,
  PREFIX_URL: `${apiUrl}prefix-name`,
};
