


import {ShopStatus,ShopButtonStatus} from "components/fragments/ShopStatus";
import React, { useState, useEffect,useRef } from "react";
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import { Link, useParams, NavLink } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Icon } from '@iconify/react';

import ipiechart from "assets/svg/PieChart.svg";
import icard from "assets/svg/card.svg";
import ihistory from "assets/svg/History.svg";
import ishop2 from "assets/svg/Shop2.svg";
import itag from "assets/svg/tag.svg";
import iusercircle from "assets/svg/UserCircle.svg";
import iuserid from "assets/svg/UserId.svg";
import Avatar from '@mui/material/Avatar';
import { Formik } from 'formik';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';

import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { getGender } from "../../../../redux/handlers/master/gender";
import { getNationality } from "../../../../redux/handlers/master/nationality";
import { getPrefix } from "../../../../redux/handlers/master/prefix";
import { apiImageUrl } from "../../../../constants/server/api";
import { useAppDispatch ,useAppSelector} from "../../../../redux/store/configureStore"; 

import {date,months,years,contact,contactinitial} from "mocks/shop";
import { updateUserShop,createUserShop, getUserShopById} from "../../../../redux/handlers/master/userShop"

export function Contact () { 
    const dispatch = useAppDispatch();
    const { shopID } = useParams();


    useEffect(()=>{
        if (shopID!=0) {
            dispatch(getUserShopById(shopID));
        }
    },[shopID])

    const userShopReducer = useAppSelector(
        (state) => state.userShopReducer
      );

      const monthIndexToThaiMonth = {
        0: "มกราคม",
        1: "กุมภาพันธ์",
        2: "มีนาคม",
        3: "เมษายน",
        4: "พฤษภาคม",
        5: "มิถุนายน",
        6: "กรกฎาคม",
        7: "สิงหาคม",
        8: "กันยายน",
        9: "ตุลาคม",
        10: "พฤศจิกายน",
        11: "ธันวาคม"
      };
      
      function convertDateToThaiFields(date) {
        let dateNew = new Date(date)
        if (dateNew) {             
        const year = dateNew.getFullYear() + 543; // เปลี่ยนจาก ค.ศ. เป็น พ.ศ.
        const month = monthIndexToThaiMonth[dateNew.getMonth()];
        const day = dateNew.getDate();
        return { date: day, month, year };
        }
       
      }

      useEffect(() => {
        if (userShopReducer.data) {            
           let data = userShopReducer.data.data
           if(data && data.length>0){
           let date =  convertDateToThaiFields( data[0].birth_date)
           
               let dataTranfer ={
                "id": data[0].id,
                "code": data[0].code,
                "image_path": data[0].image_path,
                "prefix_name_id": data[0].prefix_name_id,
                "first_name": data[0].first_name,
                "last_name": data[0].last_name,
                "date":date.date,
                "month":date.month,
                "year":date.year,
                "gender_id": data[0].gender_id,
                "nationality_id": data[0].nationality_id,
                "phone_number": data[0].phone_number,
                "is_status": data[0].is_status
               }
               setFormdata(dataTranfer)
           }
            
            
        }
      }, [userShopReducer]);


  const [genderList, setGenderList] = useState([])
  useEffect(() => {
     dispatch(getGender());
 }, []); 

 const genderReducer = useAppSelector(
     (state) => state.genderReducer
   );

   useEffect(() => {
     if (genderReducer.data) {            
      setGenderList(genderReducer.data.data); 
     }
   }, [genderReducer]);

  const gender = [
  ];

  if (genderList && genderList.length>0) {
      for (const element of genderList) {
          gender.push({label:element.name,
              value:element.id
          })
      }
  }


  const [nationalityList, setNationalityList] = useState([])
  useEffect(() => {
     dispatch(getNationality());
 }, []); 

 const nationalityReducer = useAppSelector(
     (state) => state.nationalityReducer
   );

   useEffect(() => {
     if (nationalityReducer.data) {            
      setNationalityList(nationalityReducer.data.data); 
     }
   }, [nationalityReducer]);

  const nationality = [
  ];

  if (nationalityList && nationalityList.length>0) {
      for (const element of nationalityList) {
          nationality.push({label:element.name,
              value:element.id
          })
      }
  }


  const [prefixList, setPrefixList] = useState([])
  useEffect(() => {
     dispatch(getPrefix());
 }, []); 

 const prefixReducer = useAppSelector(
     (state) => state.prefixReducer
   );

   useEffect(() => {
     if (prefixReducer.data) {            
      setPrefixList(prefixReducer.data.data); 
     }
   }, [prefixReducer]);

  const prefix = [
  ];

  if (prefixList && prefixList.length>0) {
      for (const element of prefixList) {
          prefix.push({label:element.name,
              value:element.id
          })
      }
  }


    const inputFileRef = useRef(null);
    const [image, setImage] = useState(null);
    


    const [formdata, setFormdata] = useState(contactinitial)

    const schema = yup.object().shape({
        prefix_name_id: yup.string().required(),
        first_name: yup.string().required(),
        last_name: yup.string().required(),

        date: yup.string().required(),
        month: yup.string().required(),
        year: yup.string().required(),
        gender_id: yup.string().required(),
        phone_number: yup.string().required(),
        is_status: yup.boolean().required(),
        
    });


    const handleFormSubmit = (values, { setSubmitting, setErrors }) => {
        const thaiMonthToIndex = {
            "มกราคม": 0,
            "กุมภาพันธ์": 1,
            "มีนาคม": 2,
            "เมษายน": 3,
            "พฤษภาคม": 4,
            "มิถุนายน": 5,
            "กรกฎาคม": 6,
            "สิงหาคม": 7,
            "กันยายน": 8,
            "ตุลาคม": 9,
            "พฤศจิกายน": 10,
            "ธันวาคม": 11
          };
    
          const christianYear = values.year - 543;
        const monthIndex = thaiMonthToIndex[values.month];
        const formattedDate = new Date(christianYear, monthIndex, values.date);
        const formData = new FormData();
        formData.append('shop_id',shopID);
        formData.append('image_path',image);
        formData.append('prefix_name_id',values.prefix_name_id);
        formData.append('birth_date',formattedDate);
        formData.append('first_name',values.first_name);
        formData.append('last_name',values.last_name);
        formData.append('gender_id',values.gender_id);
        formData.append('phone_number',values.phone_number);
        formData.append('is_status',values.is_status);
        if (values.nationality_id) {
            formData.append('nationality_id',values.nationality_id);
        }

        if (values.id) {
            dispatch(updateUserShop(values.id,formData))
            .then((result) => {
              setSubmitting(false);
              alert('success')
            })
            .catch(() => {         
              setSubmitting(false);
            }); 
        } else {
            dispatch(createUserShop(formData))
            .then((result) => {
              setSubmitting(false);
              alert('success')
            })
            .catch(() => {         
              setSubmitting(false);
            }); 
        }
         
      };
    

    return (
        <>
            
            <div className="bg-white  rounded-[10px] drop-shadow py-3 mb-4 p-[25px]">
                <div className="text-[#31A7DE] font-bold text-lg mb-5">ข้อมูลผู้ติดต่อ</div>
                <div className="text-[#4C4E64]/[0.68]  text-base mb-5">ภาพโปรไฟล์</div>

                

                <Formik
                    
                    initialValues={formdata}
                    validationSchema={schema}
                    enableReinitialize
                    onSubmit={handleFormSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        submitForm
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="flex items-center mb-9">
                                <Avatar
                                    alt=""
                                    src={image ? URL.createObjectURL(image)  : `${values.image_path}`}
                                    sx={{ width: 101, height: 101 }}
                                />
                                <div className="ml-6">
                                    <Button 
                                        variant="contained" 
                                        className="w-[140px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[38px] mr-3"
                                        onClick={() => inputFileRef.current.click()}
                                    >
                                        อัปโหลดรูปภาพ
                                    </Button>
                                    <Button 
                                        variant="outlined" 
                                        className="w-[62px] border-[#FF4D49]/[0.5] text-[#FF4D49] rounded-[8px] text-sm mr-3  h-[38px]"
                                        onClick={() => {
                                            inputFileRef.current.value = ""
                                            setImage(null)
                                        }}
                                    >
                                        ลบ
                                    </Button>
                                    

                                    <div className="text-[#4C4E64]/[0.68]  text-xs mt-5">อัพโหลดไฟล์ JPG, GIF or PNG. ขนาดไม่เกิน 800K</div>
                                    <input
                                        ref={inputFileRef}
                                        accept="image/*"
                                        hidden
                                        id="avatar-image-upload"
                                        type="file"
                                        onChange={(event) => {
                                            const newImage = event.target?.files?.[0];
                                            if (newImage) { 
                                                setImage(newImage);  // เก็บเป็นไฟล์แทน URL
                                                setFieldValue('image_path', newImage); // เก็บไฟล์ไว้ใน form data
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="grid grid-cols-12 gap-7 mb-6">
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField 
                                        disabled
                                        label="รหัสผู้ติดต่อ" 
                                        value={values.code}
                                        name='code'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!errors.code}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    />
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField 
                                        
                                        select
                                        label="คำนำหน้า"
                                        name='prefix_name_id'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.prefix_name_id}
                                    
                                        error={!!errors.prefix_name_id}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    >
                                        {prefix.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField
                                        
                                        label="ชื่อ"
                                        name='first_name'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.first_name}
                                        error={!!errors.first_name}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    />
                                    
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField
                                        
                                        label="นามสกุล"
                                        name='last_name'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.last_name}
                                        error={!!errors.last_name}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    />
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField 
                                        
                                        select
                                        label="วันที่"
                                        name='date'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.date}
                                    
                                        error={!!errors.date}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    >
                                        {date.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField 
                                        
                                        select
                                        label="เดือนเกิด"
                                        name='month'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.month}
                                    
                                        error={!!errors.month}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    >
                                        {months.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField 
                                        
                                        select
                                        label="ปีเกิด"
                                        name='year'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.year}
                                    
                                        error={!!errors.year}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    >
                                        {years().map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField 
                                        
                                        select
                                        label="เพศ"
                                        name='gender_id'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.gender_id}
                                    
                                        error={!!errors.gender_id}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    >
                                        {gender.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField 
                                        
                                        select
                                        label="สัญชาติ"
                                        name='nationality_id'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    
                                        value={values.nationality_id}
                                        error={!!errors.nationality_id}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    >
                                        {nationality.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="lg:col-span-3 col-span-6">
                                    <TextField
                                        
                                        label="เบอร์โทร"
                                        name='phone_number'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phone_number}
                                        error={!!errors.phone_number}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                    />
                                </div>
                            </div>

                            <div className="mb-6">
                                {values.is_status!=undefined
                                &&
                                <FormControl>
                                <div>สถานะ</div> 
                                <RadioGroup
                                    row
                                    aria-labelledby=""
                                    name="is_status"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={ values.is_status}
                    
                                >
                                    <FormControlLabel value="true" control={<Radio />} label="เปิดใช้งาน" />
                                    <FormControlLabel value="false" control={<Radio />} label="ปิดใช้งาน" />
                                </RadioGroup>
                            </FormControl>}
                             
                            </div>

                            <div className="flex justify-end mb-7">
                                    <Button 
                                        variant="outlined" 
                                        className="w-[111px] border-[#6D788D]/[0.5] text-[#6D788D] rounded-[8px] text-sm mr-3  h-[44px]"
                                        href="/manage/shop/list"
                                    >
                                        ยกเลิก
                                    </Button>
                                    <Button 
                                        variant="contained" 
                                        className="w-[111px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[44px] mr-3"
                                        type="submit"
                                    >
                                        บันทึก
                                    </Button>
                                    
                            </div>
                        </form>
                    )}
                </Formik>
               
            </div>
        </>
    )
}


