
import React, { useState, useEffect,useRef } from "react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import { Link, useParams, NavLink, useNavigate } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import DiscountIcon from '@mui/icons-material/Discount';
import PlaceSharpIcon from '@mui/icons-material/PlaceSharp';
import MapSharpIcon from '@mui/icons-material/MapSharp';
import RedeemIcon from '@mui/icons-material/Redeem';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TextField from '@mui/material/TextField';

import { Formik } from 'formik';
import * as yup from 'yup';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker,TimePicker  } from '@mui/x-date-pickers';

import {campaigninitial,campaign} from "mocks/campaign";

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import InfoIcon from '@mui/icons-material/Info';
import Avatar from '@mui/material/Avatar';

import {QuillCustom} from "components/fragments/QuillCustom";

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import CurrencyTextField from '@lupus-ai/mui-currency-textfield'

import DownloadExcel from "components/utils/DownloadExcel";
import IconButton from '@mui/material/IconButton';
import { 
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
 } from "@mui/x-data-grid";
import AddIcon from '@mui/icons-material/Add';

import { Icon } from '@iconify/react';
import ic_edit from "assets/svg/ic_edit.svg";

import {ModalSelectCouponShop} from "components/layouts/ModalSelectCouponShop";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import PinDropIcon from '@mui/icons-material/PinDrop';
import {ModalSuccess} from "components/layouts/ModalSuccess";

import { useAppDispatch, useAppSelector } from "../../../redux/store/configureStore";
import { getCouponGeneralById, createCouponGeneral, updateCouponGeneral, setCouponPayload } from "../../../redux/handlers/master/coupon";
import { getSurvey } from "../../../redux/handlers/master/survey";
import { createCouponShop, deleteCouponShop, downloadExcelCouponShop, getCouponShopPagination } from "../../../redux/handlers/master/couponShop";

export function CouponCreate () {
    const dispatch = useAppDispatch();
    const couponReducer = useAppSelector((state) => state.couponReducer);
    let { couponID } = useParams();
    // const [coupun, setCoupun] = useState(true);
    const [tab, seTab] = React.useState(0);
    const handleChangeTab = (event, newValue) => {
        seTab(newValue);
    };
    const [couponData, setCouponData] = useState();

    useEffect(() => {
        if (couponID) {
            dispatch(getCouponGeneralById(couponID));
        }
    }, [couponID, dispatch]);

    useEffect(() => {
        if (couponReducer.data) {
            const responseData = couponReducer.data.data;
            const data = {
                ...responseData,
                date: [responseData.start_date, responseData.end_date],
                display_date: responseData.campaign ? dayjs(responseData.campaign.display_date) : null,
                display_time: responseData.campaign ? dayjs(responseData.campaign.display_time) : null,
                campaign_name: responseData.campaign ?  responseData?.campaign.name : null
            };

            setCouponData(data);
            dispatch(setCouponPayload(data));
        }
    }, [couponReducer.data]);
    
    return (
        <>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>
            <div className="text-[#4C4E64]/[.87] text-2xl font-semibold my-5">จัดการโปรโมชั่น</div>
            <CampaignSetting couponID={couponID} couponData={couponData} />
            <div className="flex">
                <div className="mr-5">
                    <div className="bg-white rounded-[15px] w-[272px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)]  py-[15px]">
                        <Tabs
                            value={tab}
                            orientation="vertical"
                            onChange={handleChangeTab}
                            sx={{
                                '& .MuiTabs-indicator':{
                                    left:0
                                }
                            }}
                        >
                            <Tab label="ตั้งค่าคูปอง" 
                                icon={<DiscountIcon className="mr-3 text-xl mb-0" />} 
                                className="min-h-[50px] items-center justify-start flex-row flex" 
                            />
                            <Tab label="ตั้งค่าจุดใช้สิทธิ" 
                                icon={<PinDropIcon className="mr-3 text-xl mb-0" />} 
                                className="min-h-[50px] items-center justify-start flex-row flex" 
                                disabled={!couponID} 
                            />  
                        </Tabs>
                    </div>
                </div>
                <div className="grow">
                    <TabPanel value={tab} index={0}>
                        <Coupon couponData={couponData} />
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <PrizePickupPoint couponID={couponID} /> 
                    </TabPanel>
                </div>
            </div>
        </>
    );
}

const CampaignSetting = ({ couponID, couponData }) => {
    const dispatch = useAppDispatch();
    const schema = yup.object().shape({
        campaign_name: yup.string().required(),
        date: yup.array().required(),
        display_date: yup.string().required(),
        display_time: yup.string().required(),
    });
    const [formdata, setFormdata] = useState(null)
    const initialFormValues = formdata || {
        campaign_name: '',
        date: ['', ''],
        display_date: null,
        display_time: null
    };

    useEffect(() => {
        setFormdata(couponData)
    }, [couponData]);

    return (
        <div className="bg-white rounded-[15px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)]  mb-7 pt-[15px]">
            <Formik   
                initialValues={initialFormValues}
                validationSchema={schema}
                enableReinitialize
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    submitForm
                }) => (
                    <form className="px-[25px] py-[25px]">
                        <div className="grid grid-cols-12 gap-10 mb-6">
                            <div className="col-span-6">
                                <div className="grid grid-cols-12  gap-5 ">
                                    <div className="col-span-6">
                                        <TextField 
                                            label="ชื่อแคมเปญ *" 
                                            value={values.campaign_name}
                                            name='campaign_name'
                                            onChange={handleChange}
                                            onBlur={() => {
                                                dispatch(setCouponPayload(values));
                                            }}
                                            error={!!errors.campaign_name}
                                            className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        />
                                    </div>
                                    <div className="col-span-6">
                                        <div className="formdaterange">
                                            <label className="mb-1">เลือกช่วงวันที่</label>
                                            <DateRangePicker 
                                                onChange={(value) => {
                                                    setFieldValue('date',value)
                                                    dispatch(setCouponPayload({...values, date: value}));
                                                }} 
                                                value={values.date} 
                                                calendarIcon={<CalendarMonthIcon className="text-[#31A7DE]" />}
                                                className={`daterang-lg w-full ${!!errors.date  ? 'error' : ''} `}
                                                name="date"
                                                error={!!errors.date}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-span-3">
                                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                                            <DatePicker
                                                value={values.display_date}
                                                onChange={(value) => {
                                                    setFieldValue('display_date', value)
                                                    dispatch(setCouponPayload({...values, display_date: value}));
                                                }}
                                                error={!!errors.display_date}
                                                label="วันที่แสดงผล"
                                                className="w-full"
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className="col-span-3">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <TimePicker
                                                value={values.display_time}
                                                onChange={(value) => {
                                                    setFieldValue('display_time', value)
                                                    dispatch(setCouponPayload({...values, display_time: value}));
                                                }}
                                                error={!!errors.display_time}
                                                label="เวลาที่แสดงผล"
                                                className="w-full"
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className="col-span-6 self-center">
                                        <div className="bg-[#FFF4CC] px-5 py-2 rounded-lg inline-block">
                                            <div className="flex items-center">
                                                <InfoIcon className="text-[16px] mr-3 text-[#0E21F2]" />
                                                <div className="text-[12px] text-[#0E21F2] font-semibold">  วันที่แสดงคูปองในระบบของนักท่องเที่ยว</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-6">
                                <div className="border-[1px] px-[25px] py-[30px] bg-[#FAFAFA] inline-block border-[#31A7DE] rounded-lg ">
                                    <div className=" flex items-center">
                                        <div className="mr-10">
                                            <img src="/images/campaign4.png" className="w-[80px]" />
                                        </div>
                                        <div className="px-4 grow">
                                            <div className="text-left text-[#31A7DE] font-bold">รับคูปอง</div>
                                            <div className="text-left text-[#000000] text-[12px]"><b>ตัวอย่าง</b>  รับคูปองส่วนลดใช้ส่วนลดที่โรงแรมที่เข้าร่วม</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
}

const Coupon = ({ couponData }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const couponReducer = useAppSelector((state) => state.couponReducer);
    const surveyReducer = useAppSelector((state) => state.surveyReducer);
    const [modalOpen, setModalOpen] = useState(false)
    const [campaignData, setCampaignData] = useState(null)
    const [surveyList, setSurveyList] = useState(null)
    const survey = [];
    const schema = yup.object().shape({
        // coupon_detail: yup.string().required(),
    });
    const [formdata, setFormdata] = useState(null)
    const inputFileRef = useRef(null);
    const [image, setImage] = useState(null);
    let initialFormValues = formdata || {
        id: null,
        image_path: null,
        code: '',
        coupon_name: '',
        coupon_detail: '',
        description: '',
        terms_and_conditions: '',
        usage_limit: '',
        is_unlimited_usage: false,
        per_member_limit: '',
        minimum_spending: '',
        is_minimum_spending: false,
        discount_value: '',
        discount_type: '',
        date: [],
        is_set_date: false,
        survey_id: null,
        is_survey_questions: false
    };
    const handleFormSubmit = async (values, { setSubmitting }) => {
        try {
            // validate form
            if (
                !campaignData.campaign_name
                || campaignData.date.length == 0
                || !campaignData.display_date
                || !campaignData.display_time
                || (!values.is_unlimited_usage && !values.usage_limit)
                || !values.per_member_limit
                || (!values.is_minimum_spending && !values.minimum_spending)
                || !values.discount_value
                || !values.discount_type
                || (values.is_survey_questions && !values.survey_id)
            ) {
                alert('คุณกรอกข้อมูลไม่ครบ กรุณาตรวจสอบข้อมูลของคุณอีกครั้ง')
                return
            }

            const formData = new FormData();

            // Set campaign payload
            if (campaignData.date.length > 0) {
                formData.append('start_date', campaignData.date[0]);
                formData.append('end_date', campaignData.date[1]);
            }

            if (campaignData.display_date) {
                formData.append('display_date', campaignData.display_date ? campaignData.display_date.toISOString() : null);
            }
            
            if (campaignData.display_time) {
                formData.append('display_time', campaignData.display_time ? campaignData.display_time.toISOString() : null);
            }

            if (campaignData.campaign_id) {
                formData.append('campaign_id', campaignData.campaign_id ? campaignData.campaign_id : null);
            }

            // Set coupon payload
            if (image) {
                formData.append('image_path', image);
            }

            if (values.code) {
                formData.append('code', values.code);
            }

            if (campaignData.campaign_name) {
                formData.append('coupon_name', campaignData.campaign_name);
            }

            if (values.coupon_detail) {
                formData.append('coupon_detail', values.coupon_detail);
            }

            if (values.description) {
                formData.append('description', values.description);
            }

            if (values.terms_and_conditions) {
                formData.append('terms_and_conditions', values.terms_and_conditions);
            }

            if (values.usage_limit) {
                formData.append('usage_limit', values.usage_limit);
            }

            if (values.per_member_limit) {
                formData.append('per_member_limit', values.per_member_limit);
            }

            if (values.minimum_spending) {
                formData.append('minimum_spending', values.minimum_spending);
            }

            if (values.discount_value) {
                formData.append('discount_value', values.discount_value);
            }

            if (values.discount_type) {
                formData.append('discount_type', values.discount_type);
            }

            if (values.survey_id) {
                formData.append('survey_id', values.survey_id);
            }

            formData.append('is_unlimited_usage', values.is_unlimited_usage ? values.is_unlimited_usage : false);
            formData.append('is_minimum_spending', values.is_minimum_spending ? values.is_minimum_spending : false);
            formData.append('is_set_date', values.is_set_date ? values.is_set_date : false);
            formData.append('is_survey_questions', values.is_survey_questions ? values.is_survey_questions : false);

            if (formdata && formdata.id) {
                dispatch(updateCouponGeneral(formData, formdata.id))
                    .then((response) => {
                        if (response.message == "success") {
                            alert('save success')
                        }
                    })
                    .catch((error) => {
                        console.error('Error creating coupon', error);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            } else {
                dispatch(createCouponGeneral(formData))
                    .then((response) => {
                        if (response.message == "success") {
                            alert('save success')
                            navigate(`/coupon`)
                        }
                    })
                    .catch((error) => {
                        console.error('Error creating coupon', error);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }

        } catch (error) {
            console.error('Error submitting the form', error);
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        dispatch(getSurvey());
    }, []);

    useEffect(() => {
        if (surveyReducer.data) {
            setSurveyList(surveyReducer.data.data);
        }
    }, [surveyReducer]);

    if (surveyList && surveyList.length > 0) {
        for (const element of surveyList) {
            survey.push({
                label: element.name,
                value: element.id
            })
        }
    }

    useEffect(() => {
        setFormdata(couponData)
    }, [couponData]);

    useEffect(() => {
        if (couponReducer.payload) {
            setCampaignData(couponReducer.payload)
        }
    }, [couponReducer]);

    return (
        <>
            <Formik   
                initialValues={initialFormValues}
                validationSchema={schema}
                enableReinitialize
                onSubmit={handleFormSubmit}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    submitForm
                }) => (
                    <form className="" onSubmit={handleSubmit}>
                        <div className="bg-white rounded-[15px] px-[35px] py-[1px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)]  mb-7 pt-[15px]">
                            <div className="font-bold text-2xl text-[#31A7DE] mt-2 mb-6">ตั้งค่าคูปอง</div>
                            <div className="text-[#4C4E64]/[.68] mb-6">รูปภาพขนาดแนะนำ (400 X 400 px) ขนาดไม่เกิน 1 MB </div>
                            <div className="flex items-center mb-9">
                                <Avatar
                                    alt=""
                                    src={image ? URL.createObjectURL(image) : `${values.image_path}`}
                                    sx={{
                                        width: 140, height: 140, borderRadius: '10px',
                                        '& .MuiAvatar-fallback': {
                                            display: 'none'
                                        },
                                    }}
                                    className="bg-[#C4CCE3]"
                                />
                                <div className="ml-6">
                                    <Button 
                                        variant="contained" 
                                        className="w-[140px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[38px] mr-3"
                                        onClick={() => inputFileRef.current.click()}
                                    >
                                        อัปโหลดรูปภาพ
                                    </Button>
                                    <Button 
                                        variant="outlined" 
                                        className="w-[62px] border-[#FF4D49]/[0.5] text-[#FF4D49] rounded-[8px] text-sm mr-3  h-[38px]"
                                        onClick={() => {
                                            inputFileRef.current.value = ""
                                            setImage(null)
                                        }}
                                    >
                                        ลบ
                                    </Button>
                                    <div className="text-[#4C4E64]/[0.68]  text-xs mt-5">อัพโหลดไฟล์ JPG, GIF or PNG. ขนาดไม่เกิน 800K</div>
                                    <input
                                        ref={inputFileRef}
                                        accept="image/*"
                                        hidden
                                        id="avatar-image-upload"
                                        type="file"
                                        onChange={(event) => {
                                            const newImage = event.target?.files?.[0];
                                            if (newImage) {
                                                setImage(newImage);  // เก็บเป็นไฟล์แทน URL
                                                setFieldValue('image_path', newImage); // เก็บไฟล์ไว้ใน form data
                                            }
                                        }}
                                    />
                                </div>
                            </div>   
                            <div className="mb-5">
                                <div className="grid grid-cols-12 gap-4 mb-7 items-center">
                                    <div className="col-span-6">
                                        <TextField 
                                            label="รหัสคูปอง" 
                                            value={values.code}
                                            name='code'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={!!errors.code}
                                            className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-span-6">
                                        <TextField 
                                            label="ชื่อคูปอง/Voucher" 
                                            value={campaignData?.campaign_name}
                                            name='coupon_name'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={!!errors.coupon_name}
                                            className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                            disabled
                                            InputLabelProps={{
                                                shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-12">
                                        <TextField 
                                            label="รายละเอียดอย่างย่อ (ไม่เกิน 200 ตัวอักษร )"
                                            value={values.coupon_detail}
                                            name='coupon_detail'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={!!errors.coupon_detail}
                                            className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                            inputProps={{ maxLength: 200 }}
                                            InputLabelProps={{
                                                shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-5">
                                <div className="text-[#4C4E64]/[.87] text-lg mb-2">คำอธิบาย</div>
                                <div className="">
                                    <QuillCustom 
                                        value={values.description} 
                                        onChange={(value) => {
                                            setFieldValue('description', value)
                                        }} 
                                        placeholder="ระบุรายละเอียด"
                                    />
                                </div>
                            </div>
                            <div className="mb-10">
                                <div className="text-[#4C4E64]/[.87] text-lg mb-2">เงื่อนไขในการใช้สิทธิ</div>
                                <div className="">
                                    <QuillCustom 
                                        value={values.terms_and_conditions} 
                                        onChange={(value) => {
                                   
                                            setFieldValue('terms_and_conditions', value)
                                        }} 
                                        placeholder="ระบุเงื่อนไขในการใช้สิทธิ"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="bg-white rounded-[15px] px-[35px] py-[15px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)]  mb-7 pt-[15px]">
                            <div className="font-bold text-2xl text-[#31A7DE] mt-4 mb-9">ตั้งค่าเงื่อนไขคูปอง</div>
                            <div className="grid grid-cols-12 gap-4 mb-7 items-center">
                                <div className="col-span-3">
                                    <TextField 
                                        type="number"
                                        label="กำหนดจำนวนการใช้สิทธิ์คูปอง*" 
                                        value={values.usage_limit}
                                        name='usage_limit'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!errors.usage_limit}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        disabled={values.is_unlimited_usage}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    />
                                </div>
                                <div className="col-span-1"></div>
                                <div className="col-span-3">
                                {values.is_unlimited_usage}
                                    <FormControlLabel 
                                        className="text-[#4C4E64]/[.68]" 
                                        control={<Switch  />} 
                                        label="ไม่จำกัดจำนวนสิทธิ์"
                                        name="is_unlimited_usage"
                                        onChange={(e) => {
                                            setFieldValue('is_unlimited_usage', e.target.checked)

                                            if(e.target.checked){
                                                setFieldValue('usage_limit', '')
                                            }
                                        }}
                                        onBlur={handleBlur}
                                        value={values.is_unlimited_usage}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-12 gap-4 mb-7 items-center">
                                <div className="col-span-3">
                                    <TextField 
                                        type="number"
                                        label="กำหนดจำนวนรางวัลต่อ 1 สมาชิก*"
                                        value={values.per_member_limit}
                                        name='per_member_limit'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!errors.per_member_limit}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        InputLabelProps={{
                                            shrink: true,  // บังคับให้ label ขยับขึ้นเมื่อมีค่าในฟิลด์
                                        }}
                                    >
                                    </TextField>
                                </div>
                                <div className="col-span-1"></div>
                                <div className="col-span-3">
                                    <div className="bg-[#FFF4CC] px-5 py-2 rounded-lg inline-block">
                                        <div className="flex items-center">
                                            <InfoIcon className="text-[16px] mr-3 text-[#0E21F2]" />
                                            <div className="text-[12px] text-[#0E21F2] font-semibold">  จำนวนของคูปองที่จะได้รับต่อ 1 สมาชิก</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-12 gap-4 mb-7 items-center">
                                <div className="col-span-3">
                                    <CurrencyTextField
                                        label="กำหนดยอดขั้นต่ำในการใช้คูปอง*"
                                        variant="outlined"
                                        value={values.minimum_spending}
                                        outputFormat="string"
                                        currencySymbol=""
                                        decimalCharacter="."
                                        digitGroupSeparator=","
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        onChange={(event, value)=> setFieldValue('minimum_spending',value)}
                                        disabled={values.is_minimum_spending}
                                    />
                                </div>
                                <div className="col-span-1"></div>
                                <div className="col-span-3">
                                    <FormControlLabel 
                                        className="text-[#4C4E64]/[.68]" 
                                        control={<Switch  />} 
                                        label="ไม่กำหนดขั้นต่ำ"
                                        name="is_minimum_spending"
                                        onChange={(e) => {
                                            setFieldValue('is_minimum_spending', e.target.checked)

                                            if(e.target.checked){
                                                setFieldValue('minimum_spending', '')
                                            }
                                        }}
                                        onBlur={handleBlur}
                                        value={values.is_minimum_spending}
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-12 gap-4 mb-7 items-center">
                                <div className="col-span-3">
                                    <CurrencyTextField
                                        label="มูลค่าคูปอง*"
                                        variant="outlined"
                                        value={values.discount_value}
                                        outputFormat="string"
                                        currencySymbol=""
                                        decimalCharacter="."
                                        digitGroupSeparator=","
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        onChange={(event, value)=> setFieldValue('discount_value', value)}
                                    />
                                </div>
                                <div className="col-span-1"></div>
                                <div className="col-span-3">
                                    <RadioGroup
                                        row
                                        aria-labelledby=""
                                        name="discount_type"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={ values.discount_type}
                        
                                    >
                                        <FormControlLabel value="percent" control={<Radio />} label="ลดเป็น %" />
                                        <FormControlLabel value="amount" control={<Radio />} label="ลดเป็นบาท" />
                                    </RadioGroup>
                                </div>
                            </div>
                            {/* <div className="grid grid-cols-12 gap-4 mb-7 items-center">
                                <div className="col-span-3">
                                    <div className="formdaterange">
                                        <label className="mb-1">เลือกช่วงวันที่</label>
                                        <DateRangePicker 
                                            onChange={(value) => {
                                                setFieldValue('date', value)
                                            }} 
                                            value={values.date} 
                                            calendarIcon={<CalendarMonthIcon className="text-[#31A7DE]" />}
                                            className={`daterang-lg w-full ${!!errors.date  ? 'error' : ''} `}
                                            name="date"
                                            error={!!errors.date}
                                            disabled={values.is_set_date}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1"></div>
                                <div className="col-span-3">
                                    <FormControlLabel 
                                        className="text-[#4C4E64]/[.68]" control={<Switch  />} 
                                        label="ไม่กำหนดวันที่ใช้" 
                                        name="is_set_date"
                                        onChange={(e) => {
                                            setFieldValue('is_set_date',e.target.checked)

                                            if(e.target.checked){
                                                setFieldValue('date',[])
                                            }
                                        }}
                                        onBlur={handleBlur}
                                        value={values.is_set_date}
                                    />
                                </div>
                            </div> */}
                            <div className="grid grid-cols-12 gap-4 mb-7 items-center">
                                <div className="col-span-3">
                                    <TextField 
                                        select
                                        label="กำหนดชุดแบบสำรวจความคิดเห็น*"
                                        name='survey_id'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.survey_id}
                                        error={!!errors.survey_id}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        disabled={!values.is_survey_questions}
                                    >
                                        {survey.length > 0 ? (
                                            survey.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem value="">ไม่มีชุดแบบสำรวจ</MenuItem>
                                        )}
                                    </TextField>
                                </div>
                                <div className="col-span-1"></div>
                                <div className="col-span-3">
                                    <FormControlLabel 
                                        className="text-[#4C4E64]/[.68]" control={<Switch  />} 
                                        label="ชุดคำถามความคิดเห็น" 
                                        name="is_survey_questions"
                                        onChange={(e) => {
                                            setFieldValue('is_survey_questions',e.target.checked)
                                            if(!e.target.checked){
                                                setFieldValue('survey_id', '')
                                            }
                                        }}
                                        onBlur={handleBlur}
                                        value={values.is_survey_questions}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end mt-7 mb-7">
                            <Button 
                                variant="outlined" 
                                className="w-[111px] border-[#6D788D]/[0.5] text-[#6D788D] rounded-[8px] text-sm mr-3  h-[44px]"
                                href="/coupon"
                            >
                                ยกเลิก
                            </Button>
                            <Button 
                                variant="contained" 
                                className="w-[111px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[44px] mr-3"
                                type="submit"
                            >
                                บันทึก
                            </Button>
                        </div>
                    </form>
                )}
            </Formik>
            <ModalSuccess setOpen={setModalOpen} open={modalOpen}  />
        </>
    )
}

const PrizePickupPoint = ({ couponID }) => {
    const dispatch = useAppDispatch();
    const couponShopReducer = useAppSelector((state) => state.couponShopReducer);
    const [couponShopList, setCouponShopList] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [totalRows, setTotalRows] = useState(0);
    const [filterText, setFilterText] = useState("");
    const setCouponShopData = (data) => {
        if (data && data.length > 0) {
            const couponShopData = data.map((element) => ({
                id: element.id,
                shopCode: element?.shop?.code ?? "",
                shopName: element?.shop?.name ?? "",
                shopImage: `${element.shop.logo_image}`,
                shopProvince: element?.shop?.subDistrict.district.province.name_th ?? "",
                shopType: element?.shop?.shopCategory.name ?? "",
                shopOpen: element?.shop?.operation_time ?? "",
                shopTelephoneNumber: element?.shop?.phone_number ?? "",
                shopStatus: element?.shop.is_status ? 1 : 0,
            }));

            setCouponShopList(couponShopData);
        } else {
            setCouponShopList([]);
        }
    };
    const handlePageChange = (newPage) => {
        setPage(newPage.page + 1);
        setPageSize(newPage.pageSize);
    };
    const handleSearch = (e) => {
        setFilterText(e.target.value);
    };
    const handleDeleteClick = (id) => {
        dispatch(deleteCouponShop(id))
        .then((response) => {
            if (response.message === "success") {
                dispatch(getCouponShopPagination(couponID, page, pageSize, filterText));
            }
        })
        .catch((error) => {
            console.error('Error creating coupon', error);
        })
    }
    const download = () => {
        dispatch(downloadExcelCouponShop(couponID, filterText))
    }
    const columns = [
        {
            field: 'shopCode',
            headerName: 'รหัสร้านค้า',
            width: 150,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <a href="" className="text-[#31A7DE]">{row.shopCode}</a>
            ),
        },
        {
            field: 'shopName',
            headerName: 'ชื่อร้านค้า',
            flex:1,
            width: 150,
            editable: false,
            renderCell: ({ row }) => (
                <div className="flex items-center">
                    <img src={row.shopImage} className="w-[70px] h-[70px] rounded-lg" />
                    <div className="ml-3">{row.shopName}</div>
                </div>
            ),
        },
        {
            field: 'shopType',
            headerName: 'ประเภทร้านค้า',
            sortable: false,
            width: 160,
        },
        {
            field: 'shopProvince',
            headerName: 'จังหวัด',
            sortable: false,
            width: 160,
            center:false,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'shopTelephoneNumber',
            headerName: 'เบอร์โทรศัพท์',
            sortable: false,
            width: 160,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'shopID',
            headerName: 'จัดการ',
            type: 'actions',
            sortable: false,
            width: 160,
            headerAlign: 'center',
            align: 'center',
            getActions: ({ id }) => {
                return [
                    <IconButton  className="w-[30px] text-white h-[30px] p-1" 
                        style={{
                            background:'#F95057',
                        }} 
                        onClick={() => {handleDeleteClick(id)}}
                    >
                        <Icon icon="solar:trash-bin-trash-outline" />
                    </IconButton>
                ];
            }
        },
    ];
    const [openAddModal, setOpenAddModal] = useState(false);
    const handleAddLocation = (id) => {
        let dataCouponShop = []

        if (id.length > 0) {
            for (const element of id) {
                dataCouponShop.push({
                    coupon_id: Number(couponID),
                    shop_id: element,
                    is_active: true
                })
            }

            dispatch(createCouponShop({ data: dataCouponShop }))
                .then((response) => {
                    if (response.message == "success") {
                        dispatch(getCouponShopPagination(couponID, 1, pageSize, filterText));
                        setOpenAddModal(false)
                    }
                })
                .catch((error) => {
                    console.error('Error creating coupon', error);
                })
                .finally(() => {});
        }
    }

    useEffect(() => {        
        dispatch(getCouponShopPagination(couponID, page, pageSize, filterText));
    }, [couponID, page, pageSize, filterText, dispatch]);

    useEffect(() => {
        if (couponShopReducer.data != null) {
            const data = couponShopReducer.data.data.data;
            setCouponShopData(data);
            setTotalRows(couponShopReducer?.data?.data?.meta?.total);
        }
    }, [couponShopReducer]);

    return (
        <>
            <div className="bg-white rounded-[15px] px-[35px] py-[1px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)]  mb-7 pt-[15px]">
                <div className="font-bold text-2xl text-[#31A7DE] mt-2 mb-6">ตั้งค่าจุดใช้สิทธิ</div>
                <div className=" py-[15px]">
                    <div className="d-flex align-items-center  mb-2 flex-wrap">
                        <Button variant="contained" className="h-[38px] text-white  bg-[#31A7DE] mr-5" startIcon={<AddIcon />}
                            onClick={() => setOpenAddModal(true)}
                        >   
                            เพิ่มร้าน / สถานที่
                        </Button>
                        <div className="mr-auto" >
                            <button className='btn btn-export-st-1 me-auto' onClick={() => download()} disabled={(totalRows == 0)}>Export</button>
                        </div>
                        <div className="">
                            <input
                                type="text"
                                className="form-control form-st-4 h-[38px] w-[300px]"
                                placeholder="ค้นหาชื่อร้าน"
                                onKeyUp={handleSearch}
                            />
                        </div> 
                    </div>
                </div>
                <div className="mb-7">
                    <DataGrid
                        getRowHeight={() => 'auto'}
                        rows={couponShopList}
                        columns={columns}
                        rowCount={totalRows}
                        paginationModel={{ page: page - 1, pageSize: pageSize }}
                        paginationMode="server"
                        onPaginationModelChange={handlePageChange}
                        pageSizeOptions={[10, 20, 30]}
                        disableRowSelectionOnClick
                        slotProps={{
                            pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
                        }}
                        sx={{ 
                            borderRadius:0,
                            '.MuiDataGrid-columnHeaderDraggableContainer': { 
                      
                            },
                            '.MuiDataGrid-topContainer':{
                                fontSize:'18px',
                                color:"#31A7DE",
                                fontWeight:700
                            },
                            '.MuiDataGrid-cell':{
                                display:'flex',
                                alignItems:'center',
                                paddingTop:'10px',
                                paddingBottom:'10px',
                                fontSize:'16px'
                            }
                        }}
                    />
                </div>
            </div>
            {openAddModal
                &&
                <ModalSelectCouponShop open={openAddModal} setOpen={setOpenAddModal} selectLocation={(id) => { handleAddLocation(id) }} couponID={couponID} />
            }
        </>
    )
}

const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/coupon">
      แคมเปญ
    </Link>,
    <Typography key="3" sx={{ color: 'text.primary' }}>
      จัดการคูปอง
    </Typography>
];

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
            <div>{children}</div>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

