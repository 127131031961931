


import {ShopStatus,ShopButtonStatus} from "components/fragments/ShopStatus";

import { ModalAllder } from "components/layouts/ModalAllder";


import React, { useState, useEffect } from "react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import ipiechart from "assets/svg/PieChart.svg";
import icard from "assets/svg/card.svg";
import ihistory from "assets/svg/History.svg";
import ishop2 from "assets/svg/Shop2.svg";
import itag from "assets/svg/tag.svg";
import iusercircle from "assets/svg/UserCircle.svg";
import iuserid from "assets/svg/UserId.svg";
import UserIdActive from "assets/svg/UserId.svg";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { Formik } from 'formik';
import * as yup from 'yup';
import IconButton from '@mui/material/IconButton';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { updateShopStatus,createShop, getShopById} from "../../redux/handlers/master/shop";
import { useAppDispatch ,useAppSelector} from "../../redux/store/configureStore"; 
import {
    Link,
    useParams,
    NavLink,
    Outlet,
    useNavigate,
  } from "react-router-dom";


export function ShopInformation () { 
    const dispatch = useAppDispatch();
    let { shopID } = useParams();
    useEffect(()=>{
        if (shopID!=0) {
            dispatch(getShopById(shopID));
        }
    },[shopID])
    const shopReducer = useAppSelector((state) => state.shopReducer);

    useEffect(() => {
        if (shopReducer.data) {
            const data = shopReducer.data.data;
            console.log(data);
            
            let dataTranfer = {
                shopStatus: data?.approve_status == 'approve'?1:data?.approve_status == 'wait'?0:2, 
                dateRegister:formatDate( data?.created_at), 
                is_status: data?.is_status,
                dateApprove:data?.approve_date?formatDate(data?.approve_date):"-", 
                approver:data?.userAdmin? `${data?.userAdmin?.first_name} ${data?.userAdmin?.last_name}`: "-",
                remark_cancel: data?.remark_cancel
            }
          
            setshop(dataTranfer)
        }
    }, [shopReducer]);


    const formatDate=(isoDate) =>{
        const date = new Date(isoDate);
      
        // Extract the components of the date
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
        const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
      
        // Combine them into the desired format
        const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
      
        return formattedDate;
      }

    const [shop, setshop] = useState({
        id: 1, 
        shopCode: 'RCPY000', 
        shopName: 'So Good Phayao', 
        shopImage: '/images/shop/shop1.png', 
        shopAddress: '222 หมู่ 17 ตำบลบ้านต๋อม อ.เมือง จ.พะเยา 56000', 
        shopType: 'ร้านอาหาร', 
        shopOpen: '11.00 น. - 24.00 น.', 
        shopTelephoneNumber: '082 190 9009', 
        shopStatus: 0, 
        dateRegister:"01/01/61 12:21", 
        dateApprove:"-", 
        approver:"-", 
        
    });

    
    const [open, setOpen] = React.useState(false);

      const approveStatus =  async()=>{  
        
        const formData = new FormData();
        formData.append('approve_status', 'approve');

        const response = await dispatch(updateShopStatus(shopID,formData));
        if (response) {
            if (response.message == "success") {
                dispatch(getShopById(shopID));
            }
           
            
        }
    }

   const handleSubmitCancel = async (value)=>{
    const formData = new FormData();
    formData.append('approve_status', 'cancel');
    formData.append('remark_cancel', value.reason);
    const response = await dispatch(updateShopStatus(shopID,formData));
    if (response) {
        if (response.message == "success") {
            setOpen(false)
            dispatch(getShopById(shopID));
        }
       
        
    }

   }

   const handleSubmitWait = async ()=>{
    const formData = new FormData();
    formData.append('approve_status', 'wait');
    const response = await dispatch(updateShopStatus(shopID,formData));
    if (response) {
        if (response.message == "success") {
            setOpen(false)
            dispatch(getShopById(shopID));
        }
       
        
    }

   }

   const handleSubmitDelete = async (value)=>{
    const formData = new FormData();
    formData.append('is_status', value);
    const response = await dispatch(updateShopStatus(shopID,formData));
    if (response) {
        if (response.message == "success") {
            setOpen(false)
            dispatch(getShopById(shopID));
        }  
    }

   }



    return (
        <>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>
            <div className="text-[#4C4E64]/[.87] text-2xl font-semibold my-5">ข้อมูลร้านค้า</div>

            <div className="flex">
                <div className=" w-[272px] mr-4">
                    <div className="bg-white  rounded-[10px] drop-shadow py-3 mb-4">
                        <div className="px-[15px] mb-4">จัดการร้านค้า</div>
                        <ul className="text-[#75788D]">
                            <li className="mb-5">
                                
                                <NavLink
                                    to="dashboard"
                                    className={({ isActive, isPending }) =>
                                        isActive ? "text-[#31a7de]" : ""
                                    }
                                    >
                                    <div className="pl-[15px] flex items-center hover:text-[#31a7de]">
                                    <img className="mr-3" src={ipiechart} alt="" /> 
                                    ภาพรวม
                                    </div>
                                </NavLink>
                            </li>
                            <li className="mb-5">
                                <NavLink
                                    to="contact"
                                    className={({ isActive, isPending }) =>
                                        isActive ? "text-[#31a7de]" : ""
                                    }
                                    >
                                    <div className="pl-[15px] flex items-center hover:text-[#31a7de]">
                                        <img className="mr-3" src={iuserid} alt="" />
                                        ข้อมูลผู้ติดต่อ
                                    </div>
                                </NavLink>


                            </li>
                            <li className="mb-5">
                                <NavLink
                                    to="information"
                                    className={({ isActive, isPending }) =>
                                        isActive ? "text-[#31a7de]" : ""
                                    }
                                    >
                                    <div className="pl-[15px] flex items-center hover:text-[#31a7de]">
                                    <img className="mr-3" src={ishop2} alt="" />
                                        ข้อมูลร้านค้า
                                    </div>
                                </NavLink>
                            </li>
                            <li className="mb-5">
                                <NavLink
                                    to="emp"
                                    className={({ isActive, isPending }) =>
                                        isActive ? "text-[#31a7de]" : ""
                                    }
                                    >
                                    <div className="pl-[15px] flex items-center hover:text-[#31a7de]">
                                        <img className="mr-3" src={iusercircle} alt="" />
                                        ข้อมูลพนักงาน
                                    </div>
                                </NavLink>
                            </li>
                            <li className="mb-5">
                                <NavLink
                                    to="history/givecoupon"
                                    className={({ isActive, isPending }) =>
                                        isActive ? "text-[#31a7de]" : ""
                                    }
                                    >
                                    <div className="pl-[15px] flex items-center hover:text-[#31a7de]">
                                        <img className="mr-3" src={ihistory} alt="" />
                                        ประวัติการแจกสิทธิ์
                                    </div>
                                </NavLink>
                            </li>

                            <li className="mb-5">
                                <NavLink
                                    to="history/receivecoupon"
                                    className={({ isActive, isPending }) =>
                                        isActive ? "text-[#31a7de]" : ""
                                    }
                                    >
                                    <div className="pl-[15px] flex items-center hover:text-[#31a7de]">
                                        <img className="mr-3" src={itag} alt="" />
                                        ประวัติการรับสิทธิ์
                                    </div>
                                </NavLink>
                            </li>
                            <li className="mb-5">
           
                                <NavLink
                                    to="bank/account"
                                    className={({ isActive, isPending }) =>
                                        isActive ? "text-[#31a7de]" : ""
                                    }
                                    >
                                    <div className="pl-[15px] flex items-center hover:text-[#31a7de]">
                                        <img className="mr-3" src={icard} alt="" />
                                        บัญชีธนาคาร
                                    </div>
                                </NavLink>

                            </li>
                            
                        </ul>
                    </div>
                    {shopID != 0
                    &&
                    <div className="bg-white rounded-[10px] drop-shadow py-3 mb-4">
                    <div className="px-[15px] text-[#75788D] text-sm">
                        <div className="flex items-center mb-4 text-base">
                            <ShopButtonStatus status={shop.shopStatus} />
                            <span className="ml-3">{shop.shopName}</span>
                        </div>
                        <div className="grid grid-cols-4 gap-4 mb-4">
                            <div className="col-span-2">วันที่สมัคร</div>
                            <div className="col-span-2">{shop.dateRegister}</div>
                        </div>
                        <div className="grid grid-cols-4 gap-4 mb-4">
                            <div className="col-span-2">วันที่อนุมัติ</div>
                            <div className="col-span-2">{shop.dateApprove}</div>
                        </div>
                        <div className="grid grid-cols-4 gap-4 mb-4">
                            <div className="col-span-2">ผู้อนุมัติ</div>
                            <div className="col-span-2">{shop.approver}</div>
                        </div>
                        <div className="grid grid-cols-4 gap-4 mb-4 items-center">
                            <div className="col-span-2">สถานะ</div>
                            <div className="col-span-2">
                                <ShopStatus status={shop.shopStatus} />
                            </div>
                        </div>
                      
                         {shop.is_status == false ?
                           <Button 
                           variant="contained" 
                           className="w-full bg-[#FF0000] text-white rounded-[8px] text-base my-2 h-[36px]"
                           onClick={()=>{handleSubmitDelete(true)}}
                            >กู้คืนบัญชี
                            </Button>: <Button 
                            variant="contained" 
                            className="w-full bg-[#FF0000] text-white rounded-[8px] text-base my-2 h-[36px]"
                            onClick={()=>{handleSubmitDelete(false)}}
                        >ยกเลิกบัญชี</Button>} 
                      
                        
                    </div>
                </div>}
                    
                   
                </div>
                <div className="grow">
                   {shopID!=0 &&
                   <>
                   {
                        shop.shopStatus == 2 ?  (
                            <div className="bg-[#F24236]  rounded-[10px] drop-shadow py-3 mb-4 border-[1px] border-[#F9D855] flex items-center px-[25px]">
                                <div className="text-lg text-[#FFF] font-semibold  mr-auto">
                                     บัญชีไม่อนุมัติ เนื่องจาก  {shop?.remark_cancel}โดย {shop.approver} {shop.dateApprove}
                                </div>
                                <Button 
                                    variant="contained" 
                                    className="w-[123px] bg-[#F24236] shadow-none text-white rounded-[8px] text-base mr-3  h-[36px]"
                                    onClick={()=>{handleSubmitWait()}}
                                >
                                    ยกเลิก
                                </Button>
                            </div>
                        ) : (
                            shop.shopStatus == 0 ? (
                                <div className="bg-[#FFFBEB]  rounded-[10px] drop-shadow py-3 mb-4 border-[1px] border-[#F9D855] flex items-center px-[25px]">
                                    <div className="text-xl text-[#000] font-bold  mr-auto">
                                        บัญชีรออนุมัติ
                                    </div>
                                    <Button 
                                        variant="contained" 
                                        className="w-[123px] bg-[#FF0000] text-white rounded-[8px] text-base mr-3  h-[36px]"
                                        onClick={() => setOpen(true)}
                                    >
                                        ไม่อนุมัติ
                                    </Button>
                                    <Button 
                                        variant="contained" 
                                        className="w-[123px] bg-[#2DC76D] text-white rounded-[8px] text-base  h-[36px]"
                                        onClick={() => {
                                            approveStatus()
                                        }}
                                    >
                                        อนุมัติร้านค้า
                                    </Button>
                                </div>
                            ) : ''
                        )
                            
                            
                        
                        
                    }
                    </>}
                    

                    

                    <Outlet />
                </div>
            </div>
            <ModalNotapproved open={open} setOpen={setOpen} setshop={setshop} handleSubmitCancel={handleSubmitCancel} />
            <div className="py-2"></div>
        </>
    )
}


const breadcrumbs = [
    <NavLink
        to="/manage/shop/list"
        className={({ isActive, isPending }) =>
            isActive ? "text-[#31a7de]" : ""
        }
        >
        <div className=" flex items-center hover:text-[#31a7de]">
            ร้านค้า
        </div>
    </NavLink>,
    
    <Typography key="3" sx={{ color: 'text.primary' }}>
      ข้อมูลร้านค้า
    </Typography>
];


const ModalNotapproved = ({open,setOpen,setshop,handleSubmitCancel}) => {
 
    const handleClose = () => setOpen(false);
    const [formdata, setFormdata] = useState({
        reason:""
    })
    const schema = yup.object().shape({
        reason: yup.string().required(),
    });

    const handleSubmitForm = async (values, { setSubmitting }) => {
        handleSubmitCancel(values)
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="bg-[#31A7DE] flex items-center w-full">
                    <div className="text-lg font-bold text-white px-5 py-2 grow mr-atuo">เหตุผล</div>
                    <IconButton onClick={() => {
                        setOpen(false)
                    }}>
                        <CloseSharpIcon className="text-white" />
                    </IconButton>
                </div>
                <Formik
                    
                    initialValues={formdata}
                    validationSchema={schema}
                    enableReinitialize
                    onSubmit={handleSubmitForm}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        submitForm
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="px-7 mt-7 py-6">
                                <TextField
                                    id="outlined-multiline-static"
                                    label="เหตุผลที่ไม่อนุมัติ"
                                    multiline
                                    rows={4}
                                    defaultValue=""
                                    placeholder="กรอกเหตุผล"
                                    fullWidth
                                    name='reason'
                                    value={values.reason}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={!!errors.reason}
                                />
                            </div>
                            <div className="px-7 ">
                                <div className="flex justify-end mb-7">
                                    <Button 
                                        variant="outlined" 
                                        className="w-[91px] border-[#6D788D]/[0.5] text-[#6D788D] rounded-[8px] text-sm mr-3  h-[38px]"
                                        onClick={() => setOpen(false)}
                                    >
                                        ยกเลิก
                                    </Button>
                                    <Button 
                                        variant="contained" 
                                        className="w-[91px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[38px] mr-3"
                                        type="submit"
                                    >
                                        บันทึก
                                    </Button>
                                        
                                </div>
                            </div>
                       
                        </form>
                    )}
                </Formik>
            </Box>
        </Modal>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: 0,
    boxShadow: 24,
    p: 0,
    borderRadius:2,
    overflow:'hidden'
};