


import {ShopStatus,ShopButtonStatus} from "components/fragments/ShopStatus";
import React, { useState, useEffect,useRef } from "react";
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import { Link, useParams, NavLink } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Icon } from '@iconify/react';

import ipiechart from "assets/svg/PieChart.svg";
import icard from "assets/svg/card.svg";
import ihistory from "assets/svg/History.svg";
import ishop2 from "assets/svg/Shop2.svg";
import itag from "assets/svg/tag.svg";
import iusercircle from "assets/svg/UserCircle.svg";
import iuserid from "assets/svg/UserId.svg";
import Avatar from '@mui/material/Avatar';
import { Formik } from 'formik';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';

import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import {prefix,date,months,years,gender,nationality,contact,contactinitial} from "mocks/shop";


export function BankAccount () { 

    const [formdata, setFormdata] = useState({
        "accountName":"",
        "accountNumber":"",
        "bankName":"",
        "bankBranch":""
    });
    const schema = yup.object().shape({
        accountName: yup.string().required(),
        accountNumber: yup.string().required(),
        bankName: yup.string().required(),
        bankBranch: yup.string().required(),
        
    });

    const bank = [
        {
            value: 'ธนาคารกสิกรไทย',
            label: 'ธนาคารกสิกรไทย',
        }
    ]
    
    

    useEffect(() => {
        setFormdata({
            "accountName":"So Good Phayao จำกัด",
            "accountNumber":"0023-67-1123",
            "bankName":"ธนาคารกสิกรไทย",
            "bankBranch":"เมืองพะเยา"
            
        })
    }, []);

    

    return (
        <>
            
            <div className="bg-white  rounded-[10px] drop-shadow py-3 mb-4 p-[25px]">
                <div className="text-[#31A7DE] font-bold text-lg mb-5">บัญชีธนาคาร</div>

                <Formik
                    
                    initialValues={formdata}
                    validationSchema={schema}
                    enableReinitialize
                    onSubmit={async (values) => {

                        alert('save')
                        
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        submitForm
                    }) => (
                        <form onSubmit={handleSubmit} className="max-w-[800px]">
           
                            <div className="grid grid-cols-12 gap-7 mb-6 " >

                                <div className="lg:col-span-6 col-span-12">
                                    <TextField  
                                        label="ชื่อบัญชี" 
                                        variant="outlined" 
                                        fullWidth 
                                        placeholder=""
                                        value={values.accountName}
                                        name="accountName"
                                        onChange={handleChange}
                                        error={!!errors.accountName}
                                    />
                                </div>
                                <div className="lg:col-span-6 col-span-12">
                                    <TextField  
                                        label="หมายเลขบัญชี" 
                                        variant="outlined" 
                                        placeholder=""
                                        fullWidth 
                                        value={values.accountNumber}
                                        name="accountNumber"
                                        onChange={handleChange}
                                        error={!!errors.accountNumber}
                                    />
                                    
                                </div>
                                <div className="lg:col-span-6 col-span-12">
                                    <TextField
                                        select
                                        name='bankName'
                                        placeholder=""
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={`${values.bankName}`}
                                        error={!!errors.bankName}
                                        className={`border-[#000] text-[#4C4E64]/[0.87] w-full `}
                                        sx={{
                                            '& .MuiSelect-select span::before': {
                                                content: "''",
                                                color:'#b3b3b3'
                                            },
                                        }}
                                    >
                                        {bank.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className="lg:col-span-6 col-span-12">
                                    <TextField  
                                        label="สาขา" 
                                        variant="outlined" 
                                        placeholder=""
                                        fullWidth 
                                        value={values.bankBranch}
                                        name="bankBranch"
                                        onChange={handleChange}
                                        error={!!errors.bankBranch}
                                    />
                                </div>
              
                            </div>

        

                            <div className="flex justify-end mb-7">
                                    <Button 
                                        variant="outlined" 
                                        className="w-[111px] border-[#6D788D]/[0.5] text-[#6D788D] rounded-[8px] text-sm mr-3  h-[44px]"
                                        href="/manage/shop/list"
                                    >
                                        ยกเลิก
                                    </Button>
                                    <Button 
                                        variant="contained" 
                                        className="w-[111px] bg-[#31A7DE] text-white rounded-[8px] text-sm  h-[44px] mr-3"
                                        type="submit"
                                    >
                                        บันทึก
                                    </Button>
                                    
                            </div>
                        </form>
                    )}
                </Formik>
               
            </div>
        </>
    )
}


