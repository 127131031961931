

import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';


import { Link, useParams, NavLink,useNavigate  } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CircleIcon from '@mui/icons-material/Circle';

import EditIcon from '@mui/icons-material/Edit';


import {TickerStarWIcon,TicketWIcon,BillCrossWIcon,
    BuildingsWIcon,ChefHatWIcon,GiftboxWIcon,UsersGroupWIcon,MaleWIcon,FemaleWIcon,PdfIcon,Excel2Icon
}  from "components/fragments/Icon";


import PropTypes from 'prop-types';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { LineChart } from '@mui/x-charts/LineChart';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';


import {date,months,years,reportType,filterDaily,filterMonthly,filteryearly}  from "mocks/dashboard";


export function Dashboard () {

    

    const data = [
        { 
            id: 1, 
            campaignName: 'คูปองพิเศษเดือน มีนาคม 2567', 
            campaignDuration: 'ระดับแคมเปญ', 
            campaignDateStart: '01/04/2567', 
            campaignDateEnd: '31/05/2567', 
            userUsage:'500', 
            total:"3,650.00",
            campaignStatus:1
        },
        { 
            id: 2, 
            campaignName: 'คูปองพิเศษเดือน มีนาคม 2567', 
            campaignDuration: 'ระดับแคมเปญ', 
            campaignDateStart: '01/04/2567', 
            campaignDateEnd: '31/05/2567', 
            userUsage:'500', 
            total:"3,650.00",
            campaignStatus:2
        },
        { 
            id: 3, 
            campaignName: 'คูปองพิเศษเดือน มีนาคม 2567', 
            campaignDuration: 'ระดับแคมเปญ', 
            campaignDateStart: '01/04/2567', 
            campaignDateEnd: '31/05/2567', 
            userUsage:'500', 
            total:"3,650.00",
            campaignStatus:0
        }
    ];

    const [campaign, setCampaign] = useState([]);

    useEffect(() => {
        setCampaign(data)

    }, []);


    const [reportTypeSelect, setReportTypeSelect] = useState(1);
    const [reportFilterSelect, setReportFilterSelect] = useState(1);

    const [reportStartSelect, setReportStartSelect] = useState();
    const [reportEndSelect, setReportEndSelect] = useState();



    const [filter, setFilter] = useState([]);
    const [reportStart, setReportStart] = useState(date);
    const [reportEnd, setReportEnd] = useState(date);
    const [labelfiltertype, setLabelfiltertype] = useState('วันที่');

    

    useEffect(() => {
        if(reportTypeSelect == 1){
            setFilter(filterDaily)
            setLabelfiltertype('วันที่')
            setReportStart(date)
            setReportEnd(date)
        }else if(reportTypeSelect == 2){
            setFilter(filterMonthly)
            setLabelfiltertype('เดือน')
            setReportStart(months)
            setReportEnd(months)
        }else if(reportTypeSelect == 3){
            setFilter(filteryearly)
            setLabelfiltertype('ปี')
            setReportStart(years)
            setReportEnd(years)
        }

    }, [reportTypeSelect]);


    return (
        <>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>

            <div className="flex flex-wrap items-center mt-[15px]">
                <div className="flex items-center mr-3 mb-3">
                    <div className="text-[#4C4E64]  mr-3 whitespace-nowrap"> เลือกดูตาม</div>
                    <TextField 
                        select
                        label=""
                        name='type'
                        onChange={(e) => {
                            setReportTypeSelect(e.target.value)
                        }}
                        value={reportTypeSelect}
                        className={`border-[#EAEAEA] text-[#4C4E64]/[0.87] w-[200px]  `}
                        sx={{
                            '& fieldset':{
                                border:'1px solid #EAEAEA',
                                borderRadius:'8px',
                                
                                color:'#4C4E64'
                            },
                            '& .MuiInputBase-root':{
                                height:'48px',
                                backgroundColor:'#FFF',
                            }
                        }}
                    
                    >
                        {reportType.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className="flex items-center mr-3 mb-3">
                    <div className="text-[#4C4E64]  mr-3 whitespace-nowrap"> กรองข้อมูล</div>
                    <TextField 
                        select
                        label=""
                        onChange={(e) => {
                            setReportFilterSelect(e.target.value)
                        }}
                        value={reportFilterSelect}
                        className={`border-[#EAEAEA] text-[#4C4E64]/[0.87] w-[200px]  `}
                        sx={{
                            '& fieldset':{
                                border:'1px solid #EAEAEA',
                                borderRadius:'8px',
                                
                                color:'#4C4E64'
                            },
                            '& .MuiInputBase-root':{
                                height:'48px',
                                backgroundColor:'#FFF',
                            }
                        }}
                    
                    >
                        {filter.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className="flex items-center mr-3 mb-3">
                    <div className="text-[#4C4E64]  mr-3 whitespace-nowrap"> จาก{labelfiltertype}</div>
                    <TextField 
                        select
                        label=""
                        onChange={(e) => {
                            setReportStartSelect(e.target.value)
                        }}
                        value={reportStartSelect}
                        className={`border-[#EAEAEA] text-[#4C4E64]/[0.87] w-[200px]  `}
                        sx={{
                            '& fieldset':{
                                border:'1px solid #EAEAEA',
                                borderRadius:'8px',
                                
                                color:'#4C4E64'
                            },
                            '& .MuiInputBase-root':{
                                height:'48px',
                                backgroundColor:'#FFF',
                            }
                        }}
                    
                    >
                        {reportStart.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className="flex items-center mr-3 mb-3">
                    <div className="text-[#4C4E64]  mr-3 whitespace-nowrap"> ถึง{labelfiltertype}</div>
                    <TextField 
                        select
                        label=""
                        onChange={(e) => {
                            setReportEndSelect(e.target.value)
                        }}
                        value={reportEndSelect}
                        className={`border-[#EAEAEA] text-[#4C4E64]/[0.87] w-[200px]  `}
                        sx={{
                            '& fieldset':{
                                border:'1px solid #EAEAEA',
                                borderRadius:'8px',
                                
                                color:'#4C4E64'
                            },
                            '& .MuiInputBase-root':{
                                height:'48px',
                                backgroundColor:'#FFF',
                            }
                        }}
                    
                    >
                        {reportEnd.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
         
            </div>
            

            <div className=" pt-[15px] ">
                <div className="flex items-center mb-5">
                    <Button 
                        className="!bg-[#FFF] !text-lg !text-[#4C4E64] !border-[#EAEAEA] !rounded-lg !py-2 !px-4 !mr-4" variant="outlined"
                    >
                      <Excel2Icon />  ส่งออกข้อมูล
                    </Button>
                    <Button 
                        className="!bg-[#FFF] !text-lg !text-[#4C4E64] !border-[#EAEAEA] !rounded-lg !py-2 !px-4 !mr-4" variant="outlined"
                    >
                       <PdfIcon /> ส่งออกข้อมูล
                    </Button>
                    <div className="text-[#93A3AB]">ข้อมูล ณ วันที่ 31 ม.ก. 2566 12:00 น.</div>
                </div>
                <div className="grid grid-cols-12 gap-7">
                    <div className="col-span-6">
                        <div className="text-[#131A22] mb-3 text-lg font-semibold">จำนวนผู้ประกอบการ</div>
                        <div className="flex flex-wrap mb-2">
                            <div className="bg-[#FFF] mb-2 border-[#EBEBEB] border-[1px] rounded-lg h-[86px] min-w-[218px] py-3 px-[20px] mr-5 flex items-center">
                                <div className="w-[36px] h-[38px] rounded-[13px] bg-[#4051A3] mr-5 px-[6px] py-[7px]">
                                    <BuildingsWIcon />
                                </div>
                                <div>
                                    <div className="mb-2">
                                        <span className="text-[#4051A3] font-semibold text-2xl">15</span>
                                        <span className="text-[#75788D] ml-3 text-sm font-light">แห่ง</span>
                                    </div>
                                    <div className="text-sm font-light">ที่พัก</div>
                                </div>
                            </div>
                            <div className="bg-[#FFF] mb-2 border-[#EBEBEB] border-[1px] rounded-lg h-[86px] min-w-[218px] py-3 px-[20px] mr-5 flex items-center">
                                <div className="w-[36px] h-[38px] rounded-[13px] bg-[#F46545] mr-5 px-[6px] py-[7px]">
                                    <ChefHatWIcon />
                                </div>
                                <div>
                                    <div className="mb-2">
                                        <span className="text-[#F46545] font-semibold text-2xl">45</span>
                                        <span className="text-[#75788D] ml-3 text-sm font-light">ร้าน</span>
                                    </div>
                                    <div className="text-sm font-light">ร้านอาหาร</div>
                                </div>
                            </div>

                            <div className="bg-[#FFF] mb-2 border-[#EBEBEB] border-[1px] rounded-lg h-[86px] min-w-[218px] py-3 px-[20px] mr-5 flex items-center">
                                <div className="w-[36px] h-[38px] rounded-[13px] bg-[#C65BCF] mr-5 px-[6px] py-[7px]">
                                    <GiftboxWIcon />
                                </div>
                                <div>
                                    <div className="mb-2">
                                        <span className="text-[#C65BCF] font-semibold text-2xl">76</span>
                                        <span className="text-[#75788D] ml-3 text-sm font-light">ร้าน</span>
                                    </div>
                                    <div className="text-sm font-light">ร้านของที่ระลึก</div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white mt-2 rounded-[15px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden p-[15px]">
                            <div className="flex px-5">
                                <div className="text-[#131A22] mb-2 mr-auto ">จำนวนการใช้งานคูปอง</div>
                                <div>
                                    จำนวน <span className="font-bold text-[#31A7DE]">500</span> 
                                </div>
                            </div>
                            <div className="mt-[-25px]">
                                <LineChart
                                    xAxis={[{ data: ['01/02', '02/02', '03/02', '04/01', '05/02','06/02','07/02'],scaleType: 'band' }]}
                                    series={[
                                        {
                                        data: [20, 50.5, 20, 80.5, 10.5, 50,60],
                                        },
                                    ]}
                            
                                    height={300}
                                    grid={{ vertical: true, horizontal: true }}
                                />
                            </div>
                            
                        </div>
                    </div>
    
                    <div className="col-span-6">
                        <div className="text-[#131A22] mb-3  text-lg font-semibold">ผู้เข้าร่วมกิจกรรม</div>
                        <div className="flex flex-wrap mb-2">
                            <div className="bg-[#FFF] mb-2 border-[#EBEBEB] border-[1px] rounded-lg h-[86px] min-w-[218px] py-3 px-[20px] mr-5 flex items-center">
                                <div className="w-[36px] h-[38px] rounded-[13px] bg-[#009FBD] mr-5 px-[6px] py-[7px]">
                                    <UsersGroupWIcon />
                                </div>
                                <div>
                                    <div className="mb-2">
                                        <span className="text-[#009FBD] font-semibold text-2xl">15</span>
                                        <span className="text-[#75788D] ml-3 text-sm font-light">คน</span>
                                    </div>
                                    <div className="text-sm font-light">ทั้งหมด</div>
                                </div>
                            </div>
                            <div className="bg-[#FFF] mb-2 border-[#EBEBEB] border-[1px] rounded-lg h-[86px] min-w-[218px] py-3 px-[20px] mr-5 flex items-center">
                                <div className="w-[36px] h-[38px] rounded-[13px] bg-[#31A7DE] mr-5 px-[6px] py-[7px]">
                                    <MaleWIcon />
                                </div>
                                <div>
                                    <div className="mb-2">
                                        <span className="text-[#31A7DE] font-semibold text-2xl">45</span>
                                        <span className="text-[#75788D] ml-3 text-sm font-light">คน</span>
                                    </div>
                                    <div className="text-sm font-light">ชาย</div>
                                </div>
                            </div>

                            <div className="bg-[#FFF] mb-2 border-[#EBEBEB] border-[1px] rounded-lg h-[86px] min-w-[218px] py-3 px-[20px] mr-5 flex items-center">
                                <div className="w-[36px] h-[38px] rounded-[13px] bg-[#E786D7] mr-5 px-[6px] py-[7px]">
                                    <FemaleWIcon />
                                </div>
                                <div>
                                    <div className="mb-2">
                                        <span className="text-[#E786D7] font-semibold text-2xl">76</span>
                                        <span className="text-[#75788D] ml-3 text-sm font-light">คน</span>
                                    </div>
                                    <div className="text-sm font-light">หญิง</div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white mt-2 rounded-[15px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden p-[15px] min-h-[335px]">
                            <div className="mb-2">ผู้เข้าร่วมตามจังหวัด</div>

                            <div className="grid grid-cols-12 gap-7">
                                <div className="col-span-6">
                                    <div className="text-[#4F4F4F]">อุดรธานี</div>
                                    <LinearProgressWithLabel  value={5} />
                                </div>
                                <div className="col-span-6">
                                    <div className="text-[#4F4F4F]">หนองคาย</div>
                                    <LinearProgressWithLabel  value={90} />
                                </div>
                                <div className="col-span-6">
                                    <div className="text-[#4F4F4F]">นครศรีธรรมราช</div>
                                    <LinearProgressWithLabel  value={40} />
                                </div>
                                <div className="col-span-6">
                                    <div className="text-[#4F4F4F]">กาญจนบุรี</div>
                                    <LinearProgressWithLabel  value={1} />
                                </div>
                                <div className="col-span-6">
                                    <div className="text-[#4F4F4F]">กรุงเทพมหานคร</div>
                                    <LinearProgressWithLabel  value={10} />
                                </div>
                                <div className="col-span-6">
                                    <div className="text-[#4F4F4F]">ชาวต่างชาติ</div>
                                    <LinearProgressWithLabel  value={25} />
                                </div>
                            </div>

                            <div>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

            


            <div className="bg-white mt-5 rounded-[15px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden pt-[15px]">
                <div className="px-[15px] pt-[15px] mb-7">
                    <div className="text-[#131A22] text-lg mb-4">แคมเปญที่เปิดอยู่ในปัจจุบัน</div>


                    <div className="flex flex-wrap">
                        <div className="border-[#EBEBEB] border-[1px] rounded-lg h-[86px] min-w-[218px] py-3 px-[20px] mr-5 flex items-center">
                            <div className="w-[36px] h-[38px] rounded-[13px] bg-[#4051A3] mr-5 px-[6px] py-[7px]">
                                <TickerStarWIcon />
                            </div>
                            <div>
                                <div className="mb-2">
                                    <span className="text-[#4051A3] font-semibold text-2xl">15</span>
                                    <span className="text-[#75788D] ml-3 text-sm font-light">แคมเปญ</span>
                                </div>
                                <div className="text-sm font-light">ทั้งหมด</div>
                            </div>
                        </div>
                        <div className="border-[#EBEBEB] border-[1px] rounded-lg h-[86px] min-w-[218px] py-3 px-[20px] mr-5 flex items-center">
                            <div className="w-[36px] h-[38px] rounded-[13px] bg-[#F46545] mr-5 px-[6px] py-[7px]">
                                <TicketWIcon />
                            </div>
                            <div>
                                <div className="mb-2">
                                    <span className="text-[#F46545] font-semibold text-2xl">45</span>
                                    <span className="text-[#75788D] ml-3 text-sm font-light">แคมเปญ</span>
                                </div>
                                <div className="text-sm font-light">ใช้งานอยู่</div>
                            </div>
                        </div>

                        <div className="border-[#EBEBEB] border-[1px] rounded-lg h-[86px] min-w-[218px] py-3 px-[20px] mr-5 flex items-center">
                            <div className="w-[36px] h-[38px] rounded-[13px] bg-[#C65BCF] mr-5 px-[6px] py-[7px]">
                                <BillCrossWIcon />
                            </div>
                            <div>
                                <div className="mb-2">
                                    <span className="text-[#C65BCF] font-semibold text-2xl">76</span>
                                    <span className="text-[#75788D] ml-3 text-sm font-light">แคมเปญ</span>
                                </div>
                                <div className="text-sm font-light">สิ้นสุดแล้ว</div>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div>
                    <DataGrid
                        getRowId={(row) => row.id}
                        getRowHeight={() => 'auto'}
                        rows={campaign}
                        disableColumnMenu  
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 5,
                            },
                        },
                        }}
                        pageSizeOptions={[10, 20, 30]}
                        
                        disableRowSelectionOnClick
                        slotProps={{
                            pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
                        }}
                        sx={{ 
                            borderRadius:0,
                            '.MuiDataGrid-topContainer':{
                                fontSize:'16px'
                            },
                            '& .MuiDataGrid-cell':{
                                display:'flex',
                                alignItems:'center',
                                paddingTop:'10px',
                                paddingBottom:'10px',
                                fontSize:'16px',
                                // border: 1,
                                // borderRight: 0,
                                // borderTop: 0,
                                // borderColor:"#D0D7E8"
                            },
                            '& .MuiDataGrid-columnSeparator':{
                                display:'none'
                            },
                            '& .MuiDataGrid-iconSeparator':{
                                display:'none'
                            }
                        }}
             
                
                    />
                </div>
            </div>
        
        
        </>
    );
}

const columns = [
    {
        field: 'campaignName',
        headerName: 'ชื่อแคมเปญ',
        flex:1,
        width: 150,
        minWidth:250,
        sortable: false,
        editable: false,
        renderCell: ({ row }) => (
            <div className="pl-5">
                <div className={` text-[#3FA5FF]`}>{row.campaignName}</div>
        
            </div>
        ),
        renderHeader: () => (
            <div className="text-[#31A7DE] font-bold pl-5">
              {'ชื่อแคมเปญ '}
            </div>
        ),
    },
    {
        field: 'campaignDuration',
        headerName: 'รูปแบบระยะเวลา',
        flex:1,
        width: 150,
        minWidth:250,
        sortable: false,
        editable: false,

        renderHeader: () => (
            <div className="text-[#31A7DE] font-bold">
              {'รูปแบบระยะเวลา '}
            </div>
        ),
    },

    {
        field: 'campaignDateStart',
        headerName: 'ระยะเวลาเริ่มต้น',
        headerClassName:'bg-[#FFF]',
        
        width: 200,
        sortable: false,
        editable: false,
        renderCell: ({ row }) => (
            <div>
                <div className="text-[#242F48] mb-1">{row.campaignDateStart}</div>
                    {
                        row.campaignStatus == 1 ? (
                            <div className="text-[10px] text-[#C4C4C4] flex items-center">
                            <CircleIcon className="text-[#00BC3A] text-[4px] mr-2 animate-ping opacity-75" /> กำลังใช้งาน
                            </div>
                        ) : ''
                    }
                    {
                        row.campaignStatus == 2 ? (
                            <div className="text-[10px] text-[#FF0000] bg-[#F2F2F2] inline-block rounded p-1 text-center  ">
                             ครบกำหนดแล้ว
                            </div>
                        ) : ''
                    }
            </div>
        ),
        renderHeader: () => (
            <div className="text-[#31A7DE] font-bold">
              {'ระยะเวลาเริ่มต้น '}
            </div>
        ),
    },
    {
        field: 'campaignDateEnd',
        headerName: 'ระยะเวลาสิ้นสุด',
        width: 200,
        sortable: false,
        editable: false,
        renderCell: ({ row }) => (
            <div>
                <div className="text-[#242F48]">{row.campaignDateEnd}</div>
                <div className=""></div>
            </div>
        ),
        renderHeader: () => (
            <div className="text-[#31A7DE] font-bold">
              {'ระยะเวลาสิ้นสุด '}
            </div>
        ),
    },
    {
        field: 'total',
        headerName: 'ยอดรวม',
        sortable: false,
        width: 140,
        renderCell: ({ row }) => (
            <div>
                <div className="mb-1">{row.total}</div>
                <div className="text-sm text-[#6E7F96]">บาท</div>
            </div>
        ),
        renderHeader: () => (
            <div className="text-right">
                <div className="text-[#31A7DE] font-bold">
                ยอดใช้รวม
                </div>
                <div className="text-[14px] text-right text-[#6E7F96]">ยอดใช้จ่าย</div>
            </div>
        ),
        align: 'right',
        headerAlign: 'right',
    },
    {
        field: 'shops',
        headerName: 'จำนวนผู้ใช้งาน',
        sortable: false,
        width: 240,
        renderCell: ({ row }) => (
            <div className="pr-5">
                <div className="mb-1">{row.userUsage}</div>
                <div className="text-sm text-[#6E7F96]">คน</div>
            </div>
        ),
        renderHeader: () => (
            <div className="text-right pr-5">
                <div className="text-[#31A7DE] font-bold">
                จำนวนผู้ใช้งาน
                </div>
                <div className="text-[14px] text-right text-[#6E7F96]">จำนวนผู้เข้าร่วมแคมเปญ</div>
            </div>
        ),
        align: 'right',
        headerAlign: 'right',
    },

];

const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Dashboard
    </Link>
];

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <BorderLinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
      ...theme.applyStyles('dark', {
        backgroundColor: theme.palette.grey[800],
      }),
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
      ...theme.applyStyles('dark', {
        backgroundColor: '#308fe8',
      }),
    },
}));