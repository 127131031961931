import * as provinceAction from "../../slices/master/province.slice";
import { http } from "../../../utils/http";
import { endPoint } from "../../../constants/server/api";
import { history } from "../../../utils/history";
import { setHeaders ,handleUnauthorized} from "../../../utils/helpers";



export const getProvince = () => {
  return async (dispatch) => {
    dispatch(provinceAction.fetchData());

    try {
      const config = {
        method: "get",
        url: `${endPoint.PROVINCE_URL}/list`,
        headers: setHeaders(),
      };

      const result = await http(config);
      dispatch(provinceAction.fetchDataSuccess(result.data));
      
    } catch (error) {
      if(error.response.statusText == "Unauthorized"){
        handleUnauthorized()
      }
      dispatch(provinceAction.fetchDataFailure());
    }
  };
};
