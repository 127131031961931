
import DownloadExcel from "components/utils/DownloadExcel";
import {ShopStatus,ShopButtonStatus} from "components/fragments/ShopStatus";
import ic_edit from "assets/svg/ic_edit.svg";
import IconButton from '@mui/material/IconButton';

import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import { Link, useParams, NavLink } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import Chip from '@mui/material/Chip';
import ic_trash from "assets/icon/solar_trash-bin-trash-outline.svg";
export function UsersList () {


    const data = [
        { 
            id: 1, 
            code: 'S0001', 
            userName:'Ex@mail.com',
            name: 'นายสถาปนิค บัวพิทักษ์', 
            phoneNumber: '082 190 9009', 
            permissions:"แอดมิน",
            status:1
        },
    ];

    const [tourist, setTourist] = useState([]);

    useEffect(() => {
        setTourist(data)

    }, []);


    const [filterText, setFilterText] = useState("");

    let filteredItems = tourist.filter((item) => {
        const nameMatches =
          item.name &&
          item.name.toLowerCase().includes(filterText.toLowerCase());
        return nameMatches;
    });


    return (
        <>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>

            <div className="text-[#131A22] text-2xl font-semibold my-5">ตั้งค่าผู้ใช้งาน</div>


            <div className="bg-white rounded-[15px] shadow-[0_5px_10px_-5px_rgba(0,0,0,0.3)] overflow-hidden pt-[15px]">
                <div className="px-[15px] py-[10px]">
                    <div className="text-[#131A22] text-2xl  px-4 font-semibold mb-5">ผู้ใช้งานระบบ</div>
                    <div className="d-flex align-items-center px-4 mb-3 flex-wrap">
                        <div className="mr-auto" >
                            <DownloadExcel data={filteredItems} filename="ผู้ใช้งานระบบ" />
                        </div>


                        <div className="me-3 ">
                            <input
                                type="text"
                                className="form-control form-st-4 h-[38px] w-[250px]"
                                placeholder="ค้นหา"
                                onKeyUp={(e) => setFilterText(e.target.value)}
                            />
                        </div>
                        <Link
                        to="/setting/users/manage"
                        className="btn px-4 h-[38px] text-white  bg-[#31A7DE]"
                        
                        >
                            เพิ่มผู้ใช้งาน
                        </Link>
                    </div>
                </div>
                <div>
                    <DataGrid
                        getRowHeight={() => 'auto'}
                        rows={filteredItems}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 5,
                            },
                        },
                        }}
                        pageSizeOptions={[10, 20, 30]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        slotProps={{
                            pagination: { labelRowsPerPage: "จำนวนรายการต่อหน้า" },
                        }}
                        sx={{ 
                            borderRadius:0,
                            '.MuiDataGrid-columnHeaderDraggableContainer': { 
                                backgroundColor:'#F5F5F7'
                            },
                            '.MuiDataGrid-topContainer':{
                                fontSize:'16px'
                            },
                            '.MuiDataGrid-cell':{
                                display:'flex',
                                alignItems:'center',
                                paddingTop:'10px',
                                paddingBottom:'10px',
                                fontSize:'16px'
                            }
                        }}
                    />
                </div>
            </div>
            
            
        </>
    );
}


const columns = [
    {
        field: 'code',
        headerName: 'รหัสผู้ใช้งาน',
        headerClassName:'bg-[#F5F5F7]',
        width: 150,
        editable: false,

        renderCell: ({ row }) => (
            <div className="text-[#31A7DE]">{row.code}</div>
        ),
    },
    {
        field: 'userName',
        headerName: 'ชื่อผู้ใช้งาน',
        headerClassName:'bg-[#F5F5F7]',
        flex:1,
        width: 150,
        editable: false,
        renderCell: ({ row }) => (
            <div className="flex items-center">
                <div className="ml-3">{row.userName}</div>
            </div>
        ),
    },
    {
        field: 'name',
        headerName: 'ชื่อ-นามสกุล',
        headerClassName:'bg-[#F5F5F7]',
        width: 110,
        flex:1,
        editable: false,
    },
    {
        field: 'phoneNumber',
        headerName: 'เบอร์โทรศัพท์',
        headerClassName:'bg-[#F5F5F7]',
        sortable: false,
        width: 160,
    },

    {
        field: 'permissions',
        headerName: 'สิทธิ์ผู้ใช้งาน',
        headerClassName:'bg-[#F5F5F7]',
        sortable: false,
        width: 160,
    },
    {
        field: 'id2',
        headerName: 'สถานะ',
        headerClassName:'bg-[#F5F5F7]',
        sortable: false,
        width: 160,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) => (
            <>
            {
                row.status == 1 ? (
                    <Chip label={"เปิดใช้งาน"} className="w-[88px]" style={{
                        color:'rgba(114, 225, 40, 0.87)',
                        background:'#EEFBE5',
                    }} />
                ) : (
                    <Chip label={"ปิดใช้งาน"} className="w-[88px]" style={{
                        color:'#FF0000',
                        background:'#ff00001f',
                    }} />
                )
            }
                
            </>
            
        ),
    },
    {
        field: 'id',
        headerName: 'จัดการ',
        headerClassName:'bg-[#F5F5F7]',
        sortable: false,
        width: 160,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) => (
            <>
                <IconButton href={`/setting/users/manage/${row.id}` } className="w-[30px] mr-3 h-[30px] p-1" style={{
                    background:'#E3E8F0',
                }} >
                    <img src={ic_edit} alt="" />
                </IconButton>
                <IconButton  className="w-[30px] mr-3 h-[30px] p-1" style={{
                    background:'#F95057',
                }} >
                    <img src={ic_trash} alt="" />
                </IconButton>
            </>
            
        ),
    },
];

const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      ตั้งค่า
    </Link>,
    <Typography key="3" sx={{ color: 'text.primary' }}>
      ตั้งค่าผู้ใช้งาน
    </Typography>
];

