export const reportType = [
  {
    value: 1,
    label: 'รายวัน',
  },
  {
    value: 2,
    label: 'รายเดือน',
  },
  {
    value: 3,
    label: 'รายปี',
  }
];


export const filterDaily = [
  {
    value: 1,
    label: '7 วันล่าสุด',
  }
];
export const filterMonthly = [
  {
    value: 1,
    label: '1 เดือนล่าสุด',
  }
];
export const filteryearly = [
  {
    value: 1,
    label: '1 ปีล่าสุด',
  }
];
    // Monthly
    // Daily
    // yearly

export const date = [
    { value: 1,label: 1},
    { value: 2,label: 2},
    { value: 3,label: 3},
    { value: 4,label: 4},
    { value: 5,label: 5},
    { value: 6,label: 6},
    { value: 7,label: 7},
    { value: 8,label: 8},
    { value: 9,label: 9},
    { value: 10,label: 10},
    { value: 11,label: 11},
    { value: 12,label: 12},
    { value: 13,label: 13},
    { value: 14,label: 14},
    { value: 15,label: 15},
    { value: 16,label: 16},
    { value: 17,label: 17},
    { value: 18,label: 18},
    { value: 19,label: 19},
    { value: 20,label: 20},
    { value: 21,label: 21},
    { value: 22,label: 22},
    { value: 23,label: 23},
    { value: 24,label: 24},
    { value: 25,label: 25},
    { value: 26,label: 26},
    { value: 27,label: 27},
    { value: 28,label: 28},
    { value: 29,label: 29},
    { value: 30,label: 30},
    { value: 31,label: 31},

];

export const months = [
    { value: 'มกราคม',label: 'มกราคม'},
    { value: 'กุมภาพันธ์',label: 'กุมภาพันธ์'},
    { value: 'มีนาคม',label: 'มีนาคม'},
    { value: 'เมษายน',label: 'เมษายน'},
    { value: 'พฤษภาคม',label: 'พฤษภาคม'},
    { value: 'มิถุนายน',label: 'มิถุนายน'},
    { value: 'กรกฎาคม',label: 'กรกฎาคม'},
    { value: 'สิงหาคม',label: 'สิงหาคม'},
    { value: 'กันยายน',label: 'กันยายน'},
    { value: 'ตุลาคม',label: 'ตุลาคม'},
    { value: 'พฤศจิกายน',label: 'พฤศจิกายน'},
    { value: 'ธันวาคม',label: 'ธันวาคม'},
]

export const years = () => {


    let currentYear = new Date().getFullYear();
    let yearLength = 100;

    let y = [];
    for(var i = currentYear;i > (currentYear - yearLength) ;i--){
        var o = { value: i,label: i}
        y.push(o)
    }

    return y


}
