
import React, { useState, useEffect } from "react";
import Chip from '@mui/material/Chip';

import iconShop from "assets/svg/shop.svg";
import IconButton from '@mui/material/IconButton';

export function ShopStatus ({status}) {

    const shopStatus = [
        {
            status:0,
            statusName:"รออนุมัติ",
            color:"rgba(109, 120, 141, 0.87)",
            bg:"#F4F4F4"
        },
        {
            status:1,
            statusName:"อนุมัติแล้ว",
            color:"rgba(114, 225, 40, 0.87)",
            bg:"#EEFBE5"
        },
        {
            status:2,
            statusName:"ยกเลิก",
            color:"#FF4956",
            bg:"#FFF0F0"
        }
    ]

    let fstatus = shopStatus.filter((t) => t.status == status)


    return (
        <>
        {
            fstatus && fstatus.length > 0 ? (
                <Chip label={fstatus[0].statusName} className="w-[88px]" style={{
                    color:fstatus[0].color,
                    background:fstatus[0].bg,
                }} />
            ) : ''
        }
        </>
        
    )
}

export function ShopButtonStatus ({status,href}) {

    const shopStatus = [
        {
            status:0,
            bg:"#FF9900"
        },
        {
            status:1,
            bg:"#2DC76D"
        },
        {
            status:2,
            bg:"#75788D"
        }
    ]

    let fstatus = shopStatus.filter((t) => t.status == status)


    return (
        <>
        {
            fstatus && fstatus.length > 0 ? (
                <IconButton href={href} className="w-[30px] h-[30px] p-1" style={{
                    background:fstatus[0].bg,
                }} >
                    <img src={iconShop} alt="" />
                </IconButton>
            ) : ''
        }
        </>
        
    )
}