
import React, { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import Chip from '@mui/material/Chip';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link, useParams, NavLink,useNavigate   } from "react-router-dom";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import PlaceSharpIcon from '@mui/icons-material/PlaceSharp';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import ClockCircle from "assets/svg/ClockCircle.svg";
import CardStar from "assets/svg/cardstar.png";

export function TouristCampaign () { 

    let { id } = useParams();
    const navigate = useNavigate();


    const data = [
        { 
            id: 1, 
            campaignName: 'มอบส่วนลด 100 บาท เมื่อใช้บริการ ร้านอาหารที่ร่วมรายการ', 
            campaignImage: '/images/campaign/c1.png',
            campaignDate: 'วันที่ 1 - 31 ม.ก. 2567 เท่านั้น', 
            dateReceiveCoupon: '1 มกราคม 2567', 
            dateUseCoupon: '1 มกราคม 2567', 
            campaignStatus:1
        },
        { 
            id: 2, 
            campaignName: 'เช็คอินโรงแรมรับคูปองใช้ที่ร้านอาหาร 200 บาทฟรี', 
            campaignImage: '/images/campaign/c2.png',
            campaignDate: 'วันที่ 1 - 31 ม.ก. 2567 เท่านั้น', 
            dateReceiveCoupon: '1 มกราคม 2567', 
            dateUseCoupon: '1 มกราคม 2567', 
            campaignStatus:1
        },
        { 
            id: 3, 
            campaignName: 'ทำภารกิจครบรับส่วนลดคาเฟ่ที่ร่วมรายการ 50.-', 
            campaignImage: '/images/campaign/c3.png',
            campaignDate: 'วันที่ 1 - 31 ม.ก. 2567 เท่านั้น', 
            dateReceiveCoupon: '1 มกราคม 2567', 
            dateUseCoupon: '1 มกราคม 2567', 
            campaignStatus:2
        },
    ];

    const [campaign, setCampaign] = useState([]);

    useEffect(() => {
        setCampaign(data)

    }, []);

    const handleClick = (id) => {
        navigate("detail/"+id);
    }

    return (
        <>
            <div className="  rounded-[10px] drop-shadow  mb-4 px-[25px]">
                <div className="text-[#4C4E64]/[0.87] font-semibold text-xl mb-5 mt-2">ประวัติการใช้งานคูปอง</div>
                <div>

                    {campaign.map((item,index) => (
                        <Card className='w-100 rounded-[8px] shadow-none mb-4' key={index} onClick={() => handleClick(item.id)}>
                            <CardActionArea className='p-[10px] flex' >
                                <div>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={item.campaignImage}
                                        alt=""
                                        width="140"
                                    />
                                </div>
                                
                                <CardContent className="grow">
                                    <div className="flex">
                                        <div className="grow">
                                            <div className="text-[#131A22] text-xl mb-2">{item.campaignName}</div>
                                            <div className="text-[#31A7DE] mb-2">{item.campaignDate}</div>
                                            <div>
                                                <span className="text-[#75788D]">วันที่รับสิทธิ์</span>
                                                <span className="text-[#31A7DE] mx-3">{item.dateReceiveCoupon}</span>
                                                <span className="text-[#75788D]">วันที่ใช้สิทธิ์</span>
                                                <span className="text-[#31A7DE] mx-3">{item.dateUseCoupon}</span>
                                            </div>
                                        </div>
                                        <div className="self-center">
                                            {
                                                item.campaignStatus == 1 ? (
                                                    <Chip label="กำลังดำเนินการ" 
                                                    className="bg-[#FF9900] text-white w-[156px]"
                                                    />
                                                ) : (
                                                    item.campaignStatus == 2 ? (
                                                        <Chip label="เสร็จสิ้น" 
                                                        className="bg-[#2DC76D] text-white w-[156px]"
                                                        />
                                                    ) : ''
                                                )
                                            }
                                            
                                        </div>
                                    </div>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    ))}

                </div>

            </div>
        </>
    )
}

export function TouristCampaignDetail(){

    let { id,campaignID } = useParams();

    const datacampaign = { 
            id: 1, 
            campaignName: 'ทำภารกิจเช็คอิน 9 วัด รับคูปอง 500 บาท', 
            campaignDate: '18 มี.ค. - 3 เม.ย. 64', 
            campaignDateEnd: '31/05/2567', 
            campaignImage: '/images/campaign/c3.png', 
            remaining:"ไม่จำกัด",
            campaignDetailShort: 'ทำภารกิจครบรับส่วนลดคาเฟ่ที่ร่วมรายการ 50.-', 
            campaignDetail: 'เป็นเพียงข้อความจำลองของอุตสาหกรรมการพิมพ์และการเรียงพิมพ์ Lorem Ipsum เป็นข้อความจำลองมาตรฐานของอุตสาหกรรม นับตั้งแต่ทศวรรษที่ 1500 เมื่อเครื่องพิมพ์ ที่ไม่รู้จักใช้ห้องครัวประเภทหนึ่งและกวนมันเพื่อสร้างหนังสือตัวอย่าง', 
            condition:'<ul style="font-size:18px;list-style-type: decimal;margin-left:20px"><li>ไม่สามารถเปลี่ยนแปลงเป็นเงินสดได้</li><li>ขออสงวนสิทธิ์ในการเปลี่ยนแปลงเงื่อนไขโดยไม่แจ้งให้ทราบล่วงหน้า</li><li>เฉพาะร้านค้าที่ร่วมรายการ</li></ul>',
            coupon:[
                { 
                    id: 1, 
                    couponType: 'คูปองส่วนลด', 
                    couponDiscount: '500 บาท',
                    couponImage:"/images/campaign/c3.png",
                    couponMinimum: '1,000', 
                    couponExpire: '22 พ.ย. 2565', 
                    couponStatus:1
                }
            ],
            mission:4
        }
    

    const dataCheckin = [
        { 
            id: 1, 
            locationCode: 'CN-0001', 
            locationName: 'วัดอนาลโยทิพยาราม', 
            locationImage: '/images/location/image1.png', 
            locationProvince: 'พะเยา', 
            coordinates: '19.177500084506693, 99.81306720867634', 
            checkinScope: '150', 
            locationStatus: 0, 
            dateCheckin:"20/08/2567 10:30"
        },
        { 
            id: 2, 
            locationCode: 'CN-0002', 
            locationName: 'วัดศรีโคมคำ', 
            locationImage: '/images/location/image1.png', 
            locationProvince: 'พะเยา', 
            coordinates: '19.177500084506693, 99.81306720867634', 
            checkinScope: '150', 
            locationStatus: 1, 
            dateCheckin:"20/08/2567 10:30"
        },
        { 
            id: 3, 
            locationCode: 'CN-0003', 
            locationName: 'วัดพระธาตุจอมทอง', 
            locationImage: '/images/location/image1.png', 
            locationProvince: 'พะเยา', 
            coordinates: 'ร้านอาหาร', 
            checkinScope: '150', 
            locationStatus: 2, 
            dateCheckin:"20/08/2567 10:30"
        },
        { 
            id: 4, 
            locationCode: 'CN-0004', 
            locationName: 'วัดพระธาตุภูขวาง', 
            locationImage: '/images/location/image1.png', 
            locationProvince: 'พะเยา', 
            coordinates: '19.177500084506693, 99.81306720867634', 
            checkinScope: '150', 
            locationStatus: 1, 
            dateCheckin:"20/08/2567 10:30"
        }
    ];

    const [coupon, setCoupon] = useState([]);
    const [checkin, setCheckin] = useState([]);
    const [campaign, setCampaign] = useState([]);


    useEffect(() => {
        setCheckin(dataCheckin)
        setCoupon(datacampaign.coupon)
        setCampaign(datacampaign)
    }, []);

    const [open, setOpen] = React.useState(false);

    const [checkindetail, setCheckindetail] = useState({});

 
    
    return (
        <div className="px-5">
            <div className="  mb-4">
                <div className="text-[#4C4E64]/[0.87] font-semibold text-2xl mb-5 mt-2">รายละเอียดแคมเปญที่เข้าร่วม</div>
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    <Link underline="hover" key="2" color="inherit" >
                        แคมเปญที่เข้าร่วม
                    </Link>,
                    <Typography key="3" sx={{ color: 'rgba(76, 78, 100, 0.87)' }}>
                        {campaign.campaignName}
                    </Typography>
                </Breadcrumbs>

            </div>
            <div className=" bg-white rounded-[10px] drop-shadow   p-[25px] mb-7">
                <div className="flex mb-7">
                    <div className="w-[185px h-[185px] mr-5">
                        <img src="/images/campaign/c3.png" className="w-[185px h-[185px] object-cover rounded-lg" />
                    </div>
                    <div className="grow flex flex-col">
                        <div className="text-[#131A22] text-3xl font-semibold mb-2">{campaign.campaignName}</div>
                        <div className="text-lg mb-auto">{campaign.campaignDetailShort}</div>
                        <div className="flex">
                            <div className="flex items-center mr-7">
                                <img className="mr-3" src={ClockCircle} alt="" />
                                {campaign.campaignDate}
                            </div>
                            <div className="flex items-center">
                                <img className="mr-3" src={CardStar} alt="" />
                                คงเหลือ: <span className="text-[#31A7DE] mx-4">{campaign.remaining}</span> สิทธิ์
                            </div>
                        </div>
                    </div>
                </div> 
                <div className="text-[#131A22] text-2xl font-semibold mb-3">รางวัลกิจกรรม</div>

                {coupon.map((item,index) => 

                    <div className="w-[293px] h-[123px] drop-shadow-sm flex px-2 py-2  mb-" key={index} style={{
                        backgroundImage:'url(/images/coupon/Subtract.png)',
                        backgroundSize:'100% 100% '
                    }}>
                        <img src={item.couponImage} className="w-[111px] h-[107px] rounded-lg object-cover" />
                        <div className="grow pl-4 py-0">
                            <div className="mb-1 text-sm">{item.couponType}</div>
                            <div className="text-[#31A7DE] text-xl font-bold ml-0 mb-2">{item.couponDiscount}</div>
                            <div className="text-[#131A22] text-xs mb-1">เงื่อนไขซื้อขั้นต่ำ  <span className="ml-1">฿{item.couponMinimum} บาท</span></div>
                            <div className="text-[#131A22] text-xs font-bold text-lg  mb-3">หมดเขต {item.couponExpire}</div>

                        </div>

                    </div>
                )}

                <div className="text-[#131A22] text-2xl font-semibold mb-3 mt-4">รายละเอียด</div>
                <div className="text-lg">
                {campaign.campaignDetail}
                </div>

                <div className="text-[#131A22] text-2xl font-semibold mb-3 mt-4">เงื่อนไขในการใช้สิทธิ</div>
                <div>
                

                <div dangerouslySetInnerHTML={{__html: campaign.condition}} />
                </div>

            </div>

            <div className=" bg-white rounded-[10px] drop-shadow   p-[25px] mb-7">
                <div className="flex items-center mb-3">
                    <div className="text-[#131A22] text-2xl font-semibold">ภารกิจทั้งหมด ({checkin.length}/{campaign.mission})</div>
                    
                    {
                        checkin.length >= campaign.mission ? (
                            <Chip label="เสร็จสิ้น" 
                                className="bg-[#2DC76D] text-white w-[156px] ml-auto text-lg"
                            />
                        ) : (
                            <Chip label="กำลังดำเนินการ" 
                                className="bg-[#FF9900] text-white w-[156px] ml-auto text-lg"
                            />
                        )
                    }
                    
                    

                    
                </div>
                <div className="text-lg mb-4">ททท. แจกส่วนลด เมื่อลูกค้าเช็คอิน ณ จุดลงทะเบียนเพื่อกระตุ้นยอดขายร้านค้าที่ร่วมรายการ</div>
                <div>
                    {checkin.map((item,index) => 
                        <Card className='w-100 rounded-[8px] shadow-none drop-shadow mb-4' key={index} >
                            <CardActionArea className='p-[10px] flex' >
                                <div className="flex-[0_0_100px]">
                                    <CardMedia
                                        component="img"
                                        height="100"
                                        image={item.locationImage}
                                        alt=""
                                        width="100"
                                    />
                                </div>
                                
                                <CardContent className="grow py-0">
                                    <div className="flex">
                                        <div className="grow">
                                            <div className="text-[#131A22] text-xl mb-1">{item.locationName}</div>
                                            <div className="text-[#75788D] flex items-center mb-2"> 
                                                <PlaceSharpIcon className="text-base mr-2" /> {item.locationProvince}</div>
                                            <Button 
                                            variant="outlined"
                                            className="text-[#31A7DE] text-xs border-[#31A7DE] rounded-full py-1 px-2"
                                            >กดดูพิกัดเช็คอิน</Button>
                                            
                                        </div>
                                        <div className="self-center">
                                            <Button 
                                            variant="contained"
                                            className="text-[#FFF]  bg-[#31A7DE]  rounded-lg text-base"
                                            
                                            onClick={() => {
                                                setCheckindetail(item)
                                                setOpen(true)
                                            }}
                                            >ดูรายการ</Button>
                                        </div>
                                    </div>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    )}
                </div>
            </div>
            <ModalCheckin  open={open} setOpen={setOpen} checkindetail={checkindetail}  />
        </div>
    )
}

const ModalCheckin = ({open,setOpen,checkindetail}) => {
 
    const handleClose = () => setOpen(false);



    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className=" flex items-center w-full">
                    <div className="text-lg font-bold text-white px-5 py-2 grow mr-atuo"></div>
                    <IconButton onClick={() => {
                        setOpen(false)
                    }}>
                        <CloseSharpIcon className="text-[#4C4E64]/[0.5]" />
                    </IconButton>
                </div>

                <div className="text-xl  p-5 text-[#000] w-[420px] mx-auto">

                    <img src={checkindetail.locationImage} className=" mb-3 rounded-lg w-full " />

                    <div className="mb-1">
                        <span>สถานที่เช็คอิน : </span>
                        <span> {checkindetail.locationName}</span>
                    </div>
                    <div className="mb-1">
                        <span>วันที่ทำรายการ : </span>
                        <span> {checkindetail.dateCheckin} น.</span>
                    </div>
                    <div>
                        <span>พิกัด: </span>
                        <span className="text-[#31A7DE]"> {checkindetail.coordinates}</span>
                    </div>
                





                </div>
    
            </Box>
        </Modal>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 448,
    bgcolor: 'background.paper',
    border: 0,
    boxShadow: 24,
    p: 1,
    borderRadius:2,
    overflow:'hidden'
};